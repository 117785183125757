<template>
    <div class="contenedor-caja">
        <div class="space-header"></div>
        <TabsButton 
            :arraySessions="['Nueva entrada','Agregar caja','Exportar']"
            :firstSelect="false"
            :vModel='(data) => tab1 = data'
            :bulReset='bulResetTab1'
            :vResetBul='() => bulResetTab1 = false'
            type="sessions"
        />
        <div class="contenedor-caja-historial">
            <TabsButton
                :arraySessions="['Movimiento','Registro de cajas']"
                :firstSelect="true"
                :vModel='(data) => tab2 = data'
                type="sessionsCards"
            />
            <!-- <div class="caja-filtro d-flex justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`" @click="abrirFiltros">Filtros<div class="simboloIzquierda" :style="`transform: rotateZ(${izquierdaDesplegable}deg);`"></div><div class="simboloDerecha" :style="`transform: rotateZ(${derechaDesplegable}deg);`"></div></div> -->
            <!-- <div class="contenedor-check1 d-flex flex-row justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`">
                <p style="height:100%; margin-bottom: 0px; margin-top:7.5px;">Movimiento</p>
                <div class="checkboxM d-flex justify-content-center align-items-center" @click="selectCheckbox3($event)"><div v-if="caja3 === true" class="cajaM"></div></div>
            </div>
            <div class="contenedor-check2 d-flex flex-row justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`">
                <p style="height:100%; margin-bottom: 0px; margin-top:7.5px;">Registro de cajas</p>
                <div class="checkboxM2 d-flex justify-content-center align-items-center" @click="selectCheckbox4($event)"><div v-if="caja4 === true" class="cajaM2"></div></div>
            </div> -->
            <!-- <div :class="`caja-filtros d-flex justify-content-around align-items-center flex-row flex-wrap ${alturaFiltro}`" :style="`padding-top:${paddingTop}px;`">
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="desde" class="label">Desde</label>
                    <input type="date" id="desde" class="input" v-model="desde" :max="hasta" @change="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="hasta" class="label">Hasta</label>
                    <input type="date" id="hasta" class="input" v-model="hasta" :min="desde" @change="actualizarFiltros" />
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="nombreCajaFiltro" class="label">Nombre</label>
                    <select name="" id="nombreCajaFiltro" class="input" v-model="nombreCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="nombreCajaFiltros in cajaSeleccionadoss" v-bind:key="nombreCajaFiltros.id" v-bind:value="nombreCajaFiltros.Nombre_caja">{{nombreCajaFiltros.Nombre_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="nombreCajaFiltro2" class="label">Nombre</label>
                    <select name="" id="nombreCajaFiltro2" class="input" v-model="nombreCajaFiltro2" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="nombreCajaFiltros in cajaSeleccionadoss" v-bind:key="nombreCajaFiltros.id" v-bind:value="nombreCajaFiltros.Nombre_caja">{{nombreCajaFiltros.Nombre_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="codigoCajaFiltro" class="label">Codigo Contable</label>
                    <select name="" id="codigoCajaFiltro" class="input" v-model="codigoCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="codigoCajaMovimientoFiltross in cajaSeleccionadoss" v-bind:key="codigoCajaMovimientoFiltross.id" v-bind:value="codigoCajaMovimientoFiltross.Cc_caja">{{codigoCajaMovimientoFiltross.Cc_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="codigoCajaFiltro2" class="label">Codigo Contable</label>
                    <select name="" id="codigoCajaFiltro2" class="input" v-model="codigoCajaFiltro2" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="codigoCajaMovimientoFiltross in cajaSeleccionadoss" v-bind:key="codigoCajaMovimientoFiltross.id" v-bind:value="codigoCajaMovimientoFiltross.Cc_caja">{{codigoCajaMovimientoFiltross.Cc_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="referenciaCajaFiltro" class="label">Referencia</label>
                    <select name="" id="referenciaCajaFiltro" class="input" v-model="referenciaCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="referenciaCajaMovimientoFiltross in movimientosCajas" v-bind:key="referenciaCajaMovimientoFiltross.id" v-bind:value="referenciaCajaMovimientoFiltross.Referencia">{{referenciaCajaMovimientoFiltross.Referencia}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="entradaFiltro" class="label">Entrada/Salida</label>
                    <select name="" id="entradaFiltro" class="input" v-model="tipoCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="tipoCajaMovimientoFiltross in tipoCajaFiltros" v-bind:key="tipoCajaMovimientoFiltross.id" v-bind:value="tipoCajaMovimientoFiltross">{{tipoCajaMovimientoFiltross}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="montoMaximo" class="label">Monto máximo</label>
                    <input type="number" id="montoMaximo" class="input" v-model.number="montoMax" @keyup="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="montoMinimo" class="label">Monto mínimo</label>
                    <input type="number" id="montoMinimo" class="input" v-model.number="montoMin" @keyup="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="montoMaximo2" class="label">Monto máximo</label>
                    <input type="number" id="montoMaximo" class="input" v-model.number="montoMax2" @keyup="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="montoMinimo2" class="label">Monto mínimo</label>
                    <input type="number" id="montoMinimo2" class="input" v-model.number="montoMin2" @keyup="actualizarFiltros"/>
                </div>
            </div> -->
            <!-- <div class="caja-mostrarioMovimientos d-flex flex-column" :style="`height:380px;`" @scroll="sistemaScroll($event)" >
                <div class="caja-enunciado d-flex flex-row align-items-center" v-if="caja3 === true" :style="`top:${numeroMarginTopScroll}px;`">
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Referencias</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Montos</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Ent / Sal</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Nombre de las cajas</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Fechas</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Codigos contables</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Concepto</div>
                    <div class="letrasSeleccionCodigoContable" style="width:100px; font-weight:bold; height:50px; padding-top:12px;"></div>
                </div>
                <div v-if="caja3 === true">
                    <div :class='`contenedor-movimientos d-flex flex-row`' v-for="movimientosCaass in movimientosCajasFiltros" v-bind:key="movimientosCaass.id">
                        <div class="letrasSeleccionCodigoContable" style="width:200px; position:relative;" >{{movimientosCaass.Referencia}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px;">{{movimientosCaass.Cantidad}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px;">{{movimientosCaass.Tipo_movimiento_caja}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px; position:relative;" >{{movimientosCaass.Nombre_caja}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px;">{{movimientosCaass.Fecha_creacion}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px;">{{movimientosCaass.Cc_caja}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px; position:relative;" >{{movimientosCaass.Concepto}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:100px;"></div>
                        <div class="d-flex justify-content-center align-items-center caja-editar-caja" :style="`right:${numeroMarginLeftScroll+50}px;`"><div class="editarSeleccionCodigoContable" @click="informacionEdicion = movimientosCaass; filtroDeEdicionMovimientos(movimientosCaass.Nombre_caja,movimientosCaass.Concepto,movimientosCaass.Cc_caja)"></div></div>
                        <div class="d-flex justify-content-center align-items-center caja-eliminar-caja" :style="`right:${numeroMarginLeftScroll}px;`"><div class="borrarSeleccionCodigoContable" @click="revisarPermisoDeEliminacion(movimientosCaass.Cantidad, movimientosCaass.Contra, movimientosCaass.Cc_caja, movimientosCaass.Codigo_de_relacion)"></div></div>
                    </div>
                </div>
                <div class="caja-enunciado d-flex flex-row align-items-center" v-if="caja4 === true">
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Nombre</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Montos</div>
                    <div class="letrasSeleccionCodigoContable" style="width:200px; font-weight:bold; height:50px; padding-top:12px;">Codigos contables</div>
                    <div class="letrasSeleccionCodigoContable" style="width:100px; font-weight:bold; height:50px; padding-top:12px;"></div>
                </div>
                <div v-if="caja4 === true">
                    <div :class='`contenedor-movimientos d-flex flex-row`' v-for="casss in cajaFiltross" v-bind:key="casss.id">
                        <div class="letrasSeleccionCodigoContable" style="width:200px; position:relative;" >{{casss.Nombre_caja}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px;">{{saldoDeLaCaja(casss.Cc_caja)}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:200px;">{{casss.Cc_caja}}</div>
                        <div class="d-flex justify-content-center align-items-center caja-editar-caja" :style="`right:${numeroMarginLeftScroll+50}px;`"><div class="editarSeleccionCodigoContable"  @click="this.$store.commit('SET_BUL_MODAL', true); bulEditaCaja = true; nombreMemoria = casss.Nombre_caja; cambioCaja1 = casss.Nombre_caja; idCaja = casss.Id_caja; codigoCaja = casss.Codigo_caja; ccCaja = casss.Cc_caja;"></div></div>
                        <div class="d-flex justify-content-center align-items-center caja-eliminar-caja" :style="`right:${numeroMarginLeftScroll}px;`"><div class="borrarSeleccionCodigoContable" @click="eliminarCaja(casss.Id_caja,casss.Nombre_caja,casss.Cc_caja)"></div></div>
                    </div>
                </div>
            </div> -->
            <!-- informacionEdicion = movimientosCaass; filtroDeEdicionMovimientos(movimientosCaass.Nombre_caja,movimientosCaass.Concepto,movimientosCaass.Cc_caja)" -->
            <Table 
                :heightTable=400
                :dataSourceFilter="movimientosCajasFiltros"
                :actionEdit="(data) => filtroDeEdicionMovimientos(data)"
                :actionDelete="(data) => revisarPermisoDeEliminacion(data)"
                :boolVisible="tab2['Movimiento']"
                :stateData="stateDataTableMovimientoCaja"
                :arrayNameColumns="[
                    {name:'Referencias',         reference: 'Referencia',           type: 'text',   width: 200},
                    {name:'Montos',              reference: 'Cantidad',             type: 'number', width: 200},
                    {name:'Ent / Sal',           reference: 'Tipo_movimiento_caja', type: 'text',   width: 200},
                    {name:'Nombre de las cajas', reference: 'Nombre_caja',          type: 'text',   width: 200},
                    {name:'Fechas',              reference: 'Fecha_creacion',       type: 'date',   width: 200},
                    {name:'Codigos contables',   reference: 'Cc_caja',              type: 'text',   width: 200},
                    {name:'Concepto',            reference: 'Concepto',             type: 'text',   width: 200},
                ]"
            />
            <Table 
                :heightTable=400
                :dataSourceFilter="cajasFiltros"
                :actionEdit="(data) => {
                    bulEditaCaja = true
                    memoriaCaja = {...data}
                    nuevoNombreCaja = data.Nombre_caja
                }"
                :actionDelete="(data) => {eliminarCaja(data)}"
                :boolVisible="tab2['Registro de cajas']"
                :stateData="stateDataTableCaja"
                :arrayNameColumns="[
                    {name:'Nombre',            reference: 'Nombre_caja', type: 'text',   width: 200},
                    {name:'Montos',            reference: 'Cc_caja',     type: 'number', width: 200, functionData: saldoDeLaCaja},
                    {name:'Codigos contables', reference: 'Cc_caja',     type: 'text',   width: 200},
                ]"
            />
        </div>
        <!-- <div class="card">
            <div class="d-flex flex-wrap flex-column flex-lg-row align-items-center justify-content-lg-around margin-responsive" style="width:100%; margin-top:50px;">
                <div class="col-11 col-sm-8 col-lg-5" style="position:relative; height:60px; margin-bottom:40px;" @mouseover="bulCajaSobre = true" @mouseleave="bulCajaSobre = false" @click="bulCajaSobre = true" @blur="bulCajaSobre = false">
                    <label for="caja" class="label">Caja seleccionada</label>
                    <div class="d-flex flex-row" style="position:relative; height:35px;">
                        <input type="text" id="caja2" class="inputCaja1" v-model="cajaSeleccionada1" @keyup="filtrarPorBusquedaCaja" />
                        <input type="text" id="caja" class="inputCaja2" v-model="cajaSeleccionada2" @keyup="filtrarPorBusquedaCaja"/>
                        <div class="sumar-otra-caja" @click="agregarNuevaCaja2"></div>
                    </div>
                    <div class="caja-cajasExistentes d-flex flex-column" v-if="bulCajaSobre === true">
                        <div class="seleccionCaja d-flex flex-row" v-for="cajaSeleccionados in cajaSeleccionadossFiltro" v-bind:key="cajaSeleccionados.id" @click="cajaSeleccionada1 = cajaSeleccionados.Codigo_caja; cajaSeleccionada2 = cajaSeleccionados.Nombre_caja; codigoDeCajaMemoria = cajaSeleccionados.Cc_caja;">
                            <div class="letrasSeleccionCaja" style="width:20%; overflow:hidden;">{{cajaSeleccionados.Codigo_caja}}</div>
                            <div class="letrasSeleccionCaja" style="width:60%; overflow:hidden;">{{cajaSeleccionados.Nombre_caja}}</div>
                            <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="editarSeleccionCaja" @click="this.$store.commit('SET_BUL_MODAL', true); bulEditaCaja = true; nombreMemoria = cajaSeleccionados.Nombre_caja; cambioCaja1 = cajaSeleccionados.Nombre_caja; idCaja = cajaSeleccionados.Id_caja; codigoCaja = cajaSeleccionados.Codigo_caja; ccCaja = cajaSeleccionados.Cc_caja;"></div></div>
                            <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="borrarSeleccionCaja" @click="eliminarCaja(cajaSeleccionados.Id_caja,cajaSeleccionados.Nombre_caja,cajaSeleccionados.Cc_caja)"></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="montoC" class="label">Monto</label>
                    <input type="text" id="montoC" class="input3" v-model="montoC"/>
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="fechaContra" class="label">Fecha</label>
                    <input type="date" id="fechaContra" class="input3" v-model="fechaC"/>
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="referenciaC" class="label">Referencia</label>
                    <input type="text" id="referenciaC" class="input3" v-model="referenciaC"/>
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="conceptoC" class="label">Concepto</label>
                    <input type="text" id="conceptoC" class="input3" v-model="conceptoC"/>
                </div>
            </div>
            <div class="col-6 d-flex flex-row justify-content-center align-items-center">
                <div class="entrada d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox d-flex justify-content-center align-items-center" @click="selectCheckbox($event)"><div v-if="caja1 === true" class="caja"></div></div>
                    <p class="texto-check">Entrada</p>
                </div>
                <div class="salida d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox2 d-flex justify-content-center align-items-center" @click="selectCheckbox2($event)"><div v-if="caja2 === true" class="caja2"></div></div>
                    <p class="texto-check">Salida</p>
                </div>
            </div>
            <div class="col-12 col-md-6 d-flex flex-row justify-content-center align-items-center" style="margin-top:10px;">
                <div class="checkbox-mantener d-flex justify-content-center align-items-center" @click="selectCheckbox6($event)"><div class="caja-mantener"></div></div>
                <p class="font-predeterminado" >Mantener informacion como plantilla</p>
            </div>
            <div class="contenedor-botones d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center margin-boton-exportar" @click=" montoC = ''; cajaSeleccionada1 = ''; cajaSeleccionada2 = ''; conceptoC = ''; referenciaC = ''; fechaC = '';" >Borar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="confirmacionParaSegundoPasoMovimientoCaja">Siguiente</button>
            </div>
            
        </div> -->
        <FormCustom
            typeContainer="window"
            title="Nueva entrada"
            textSecButton="Agregar"
            :actionSecButton="(data)=>{
                agregarMovimiento(data)
            }"
            widthWindowForm="500px"
            :exitForm='() => bulResetTab1 = true'
            :reiniciarDataForm="reiniciarFormNuevaEntrada"
            :bulWindow="tab1['Nueva entrada']"
            :limitInputsInSection="4"
            :objectForm="[
                {
                    type:'managementData',
                    array: cajaSeleccionadoss,
                    label: 'Caja seleccionada',
                    name: 'Caja_seleccionada',
                    subData1: 'Cc_caja',
                    subData2: 'Nombre_caja',
                    subData3: false,
                    functionEdit: (value) => editarCaja(value),
                    functionAdd: (value) => agregarNuevaCaja(value.Nombre_caja),
                    functionDelete: (value) => eliminarCaja(value),
                },          
                {
                    type: 'number',
                    label: 'Monto',
                    name: 'Monto',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'date',
                    label: 'Fecha',
                    name: 'Fecha',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'text',
                    label: 'Referencia',
                    name: 'Referencia',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'text',
                    width: `40%`,
                    label: 'Concepto',
                    name: 'Concepto',
                    widthComplete: true,
                    vmodel: (values) => response(values)
                },
                {
                    type: 'radio',
                    width: `250px`,
                    name: 'Tipo',
                    style: 'radio',
                    widthComplete: true,
                    array: ['Entrada','Salida'],
                    vmodel: (values) => response(values)
                },
                {
                    type: 'checkbox',
                    widthComplete: true,
                    name: 'Info_plantilla',
                    array: ['Mantener informacion como plantilla'],
                    vmodel: (values) => response(values)
                },
            ]"
        />
        <!-- nueva interfaz contra movimientos -->
        <FormCustom
            typeContainer="window"
            :title="`Contra Movimiento ${nuevoMovimientoMemo.Tipo === 'Entrada' ? 'Salida' : 'Entrada'}`"
            textSecButton="Agregar"
            :actionSecButton="(data)=>{
                agregarNuevoContraMovimiento(data)
            }"
            widthWindowForm="500px"
            :exitForm='() => bulContraCaja = false'
            :bulWindow="bulContraCaja"
            :objectForm="[
                {
                    type: 'custom',
                    widthComplete: false,
                    name: 'inputMontoTotal'
                },
                {
                    type:'managementData',
                    array: codigosContablesFiltros,
                    label: 'Código contable',
                    name: 'Codigo_contable',
                    subData1: 'Codigo',
                    subData2: 'Referencia',
                    subData3: true,
                    functionAdd: (value) => agregarCodigoContable(value),
                    functionDelete: (value) => {eliminarCodigoContable(value)},
                    functionEdit: (value) => editarCodigoContable(value)
                },             
                {
                    type: 'number',
                    label: 'Monto',
                    name: 'Monto',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'checkbox',
                    widthComplete: true,
                    name: 'Seguir_agregando',
                    array: ['Sumar Contramovimiento'],
                    vmodel: (values) => response(values)
                },
                {
                    type: 'custom',
                    widthComplete: false,
                    name: 'tableContraMovimientoInform'
                },                
            ]"
        >
            <template v-slot:inputMontoTotal>
                <div style="display:flex; justify-content:center; flex-direction:column; align-items:center;" class="container-input">
                    <label for="Monto_total" class="label">Monto Total</label>
                    <input type="text" disabled class="input" :value="montoTotalMovimientos" style="background:green; color:white; width:max-content; padding:0;text-align:center;" name="Monto_total" >
                </div>
            </template>
            <template v-slot:tableContraMovimientoInform>
                <div class="contenedor-caja-historial" style="margin: 0px 16px 16px 16px;">
                    <label for="" class="label">Contra Movimientos agregados</label>
                    <Table
                        :heightTable="300"
                        :dataSourceFilter="contraMovimientosMemo"
                        :actionEdit="(data) => {
                            bulModalEditContramovimiento = true
                            contraMovimientoSeleccionado = data
                        }"
                        :actionDelete="(data) => borrarContraMovimiento(data)"
                        :boolVisible="bulContraCaja"
                        :stateData="stateDataTableMovimientoCaja"
                        :arrayNameColumns="[
                            {name:'Monto',              reference: 'Monto',             type: 'number', width: 200},
                            {name:'Nombre', reference: 'Nombre',          type: 'text',   width: 200},
                        ]"
                    />
                </div>
            </template>
        </FormCustom>
        <ModalCustom
            :bulModal="bulModalEditContramovimiento"
            :exitModal='() => bulModalEditContramovimiento = false'
            :widthCard="'300px'"
        >
            <FormCustom
                textSecButton="Editar"
                typeStyle="column"
                :isOneButton="true"
                :objectForm="[
                    {
                        label: 'Monto',
                        type: 'number',
                        name: 'Monto',
                        valueDefault: contraMovimientoSeleccionado.Monto,
                        required: true,
                    }
                ]"
                :buttons="[,
                    {
                        text: 'Editar',
                        style: {
                            marginTop: '-40px',
                            width: '150px',
                        }
                    }
                ]"
                :actionSecButton="(data)=>{
                    editarContraMovimiento(contraMovimientoSeleccionado, data.Monto)
                    bulModalEditContramovimiento = false
                }"
            />
        </ModalCustom>
        <!-- {
            type: 'custom',
            ref: 'PARRAFFORM'
        }, -->
        <!-- <div class="contenedor-caja-historial d-flex flex-column altura-responsive2">
            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-around margin-responsive" style="width:100%; margin-top:50px;">
                <div class="col-11 col-sm-8 col-lg-5" style="position:relative; height:60px; margin-bottom:40px;">
                    <label for="caja" class="label">Nombre caja</label>
                    <input type="text" id="montoC" class="input3" v-model="nombreDeLaCaja"/>
                </div>
                <div class=" col-11 col-sm-8 col-lg-5" style="position:relative; height:60px; margin-bottom:40px;" @mouseover="bulCodigoSobre2 = true" @mouseleave="bulCodigoSobre2 = false">
                    <label for="nuevoCodigoContable" class="label">Código contable</label>
                    <div class="d-flex flex-row" style="position:relative; height:35px;">
                        <input type="text" id="nuevoCodigoContable" class="inputCodigoContable1" v-model="nuevoCodigoContable1"/>
                        <input type="text" id="nuevoCodigoContable" class="inputCodigoContable2" v-model="nuevoCodigoContable2"/>
                    </div>
                    <div class="caja-codigos-contables d-flex flex-column" v-if="bulCodigoSobre2 === true && ultimoCodigoCajaCreado !== false">
                        <div class="seleccionCodigoContable2 d-flex justify-content-center align-items-center flex-column" >
                            <div class="letrasSeleccionCodigoContable" style="width:90%; font-weight:bold; margin-bottom:10px;">Ultimo codigo contable registrado para cajas</div>
                            <div class="letrasSeleccionCodigoContable" style="width:100%; font-weight:bold;">{{ultimoCodigoCajaCreado}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contenedor-botones d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center margin-boton-exportar" @click="nuevoCodigoContable1 = ''; nuevoCodigoContable2 = ''; nombreDeLaCaja = '';" >Borar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="agregarNuevaCaja">Agregar</button>
            </div>
        </div> -->
        <FormCustom
            typeContainer="window"
            title="Agregar caja"
            textSecButton="Agregar"
            :reiniciarDataForm="reiniciarFormAgregarCaja"
            :exitForm='() => bulResetTab1 = true'
            :bulWindow="tab1['Agregar caja']"
            :actionSecButton="(data) => agregarNuevaCaja(data.nombreCaja,true)"
            :objectForm="[
                {
                    type: 'text',
                    label: 'Nombre caja',
                    name: 'nombreCaja',
                    required: true,
                },
            ]"
        />
        <!-- <div class="contenedor-caja-historial" v-if="tab1['Exportar']">
            <div class="caja-filtro d-flex justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`" @click="abrirFiltros">Filtros<div class="simboloIzquierda" :style="`transform: rotateZ(${izquierdaDesplegable}deg);`"></div><div class="simboloDerecha" :style="`transform: rotateZ(${derechaDesplegable}deg);`"></div></div>
            <div class="contenedor-check1 d-flex flex-row justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`">
                <p style="height:100%; margin-bottom: 0px; margin-top:7.5px;">Movimiento</p>
                <div class="checkboxM d-flex justify-content-center align-items-center" @click="selectCheckbox3($event)"><div v-if="caja3 === true" class="cajaM"></div></div>
            </div>
            <div class="contenedor-check2 d-flex flex-row justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`">
                <p style="height:100%; margin-bottom: 0px; margin-top:7.5px;">Registro de cajas</p>
                <div class="checkboxM2 d-flex justify-content-center align-items-center" @click="selectCheckbox4($event)"><div v-if="caja4 === true" class="cajaM2"></div></div>
            </div>
            <div :class="`caja-filtros d-flex justify-content-around align-items-center flex-row flex-wrap ${alturaFiltro}`" :style="`padding-top:${paddingTop}px;`">
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="desde" class="label">Desde</label>
                    <input type="date" id="desde" class="input" v-model="desde" :max="hasta" @change="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="hasta" class="label">Hasta</label>
                    <input type="date" id="hasta" class="input" v-model="hasta" :min="desde" @change="actualizarFiltros" />
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="nombreCajaFiltro" class="label">Nombre</label>
                    <select name="" id="nombreCajaFiltro" class="input" v-model="nombreCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="nombreCajaFiltros in cajaSeleccionadoss" v-bind:key="nombreCajaFiltros.id" v-bind:value="nombreCajaFiltros.Nombre_caja">{{nombreCajaFiltros.Nombre_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="nombreCajaFiltro2" class="label">Nombre</label>
                    <select name="" id="nombreCajaFiltro2" class="input" v-model="nombreCajaFiltro2" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="nombreCajaFiltros in cajaSeleccionadoss" v-bind:key="nombreCajaFiltros.id" v-bind:value="nombreCajaFiltros.Nombre_caja">{{nombreCajaFiltros.Nombre_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="codigoCajaFiltro" class="label">Codigo Contable</label>
                    <select name="" id="codigoCajaFiltro" class="input" v-model="codigoCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="codigoCajaMovimientoFiltross in cajaSeleccionadoss" v-bind:key="codigoCajaMovimientoFiltross.id" v-bind:value="codigoCajaMovimientoFiltross.Cc_caja">{{codigoCajaMovimientoFiltross.Cc_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="codigoCajaFiltro2" class="label">Codigo Contable</label>
                    <select name="" id="codigoCajaFiltro2" class="input" v-model="codigoCajaFiltro2" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="codigoCajaMovimientoFiltross in cajaSeleccionadoss" v-bind:key="codigoCajaMovimientoFiltross.id" v-bind:value="codigoCajaMovimientoFiltross.Cc_caja">{{codigoCajaMovimientoFiltross.Cc_caja}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="referenciaCajaFiltro" class="label">Referencia</label>
                    <select name="" id="referenciaCajaFiltro" class="input" v-model="referenciaCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="referenciaCajaMovimientoFiltross in movimientosCajas" v-bind:key="referenciaCajaMovimientoFiltross.id" v-bind:value="referenciaCajaMovimientoFiltross.Referencia">{{referenciaCajaMovimientoFiltross.Referencia}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="entradaFiltro" class="label">Entrada/Salida</label>
                    <select name="" id="entradaFiltro" class="input" v-model="tipoCajaFiltro" @change="actualizarFiltros">
                            <option class="optionCambiado" value="" ></option>
                            <option class="optionCambiado" v-for="tipoCajaMovimientoFiltross in tipoCajaFiltros" v-bind:key="tipoCajaMovimientoFiltross.id" v-bind:value="tipoCajaMovimientoFiltross">{{tipoCajaMovimientoFiltross}}</option>
                    </select>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="montoMaximo" class="label">Monto máximo</label>
                    <input type="number" id="montoMaximo" class="input" v-model.number="montoMax" @keyup="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja3 === true">
                    <label for="montoMinimo" class="label">Monto mínimo</label>
                    <input type="number" id="montoMinimo" class="input" v-model.number="montoMin" @keyup="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="montoMaximo2" class="label">Monto máximo</label>
                    <input type="number" id="montoMaximo" class="input" v-model.number="montoMax2" @keyup="actualizarFiltros"/>
                </div>
                <div class="col-5 col-sm-4 col-md-3 col-lg-2 margin-izquierda2" v-if="caja4 === true">
                    <label for="montoMinimo2" class="label">Monto mínimo</label>
                    <input type="number" id="montoMinimo2" class="input" v-model.number="montoMin2" @keyup="actualizarFiltros"/>
                </div>
            </div>
            <div class="contenedor-filtros-seleccionados">
                <div class="cuadro-filtro-seleccionado" v-for="seleccion in seleccionFiltro" v-bind:key="seleccion.id" v-bind:value="seleccion">{{limiteCaracteres(seleccion,10)}}</div>
            </div>
            <div class="col-10 col-sm-6" style="margin-top:30px;">
                <label for="formato" class="label3">Formato</label>
                <select name="" id="formato" class="input3" v-model="formatoS" >
                        <option class="optionCambiado" value="" ></option>
                        <option v-for="formato in arregloFormatos" v-bind:key="formato.id" v-bind:value="formato">{{formato}}</option>
                </select>
            </div>
            <div class="col-10 col-sm-6">
                <label for="nombreArchivo" class="label3">Nombre de archivo</label>
                <input type="text" id="nombreArchivo" class="input3" v-model="nombreDelArchivo"/>
            </div>
            <p class="exportar-informacion">Se va a exportar lo anteriormente filtrado en el apartado de caja, puedes guardar tambien estos informes</p>
            <div class="contenedor-botones d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center margin-boton-exportar" @click="formatoS = ''; seleccionFiltro = []; desde = ''; hasta = ''; nombreCajaFiltro2 = ''; nombreCajaFiltro = ''; codigoCajaFiltro = ''; codigoCajaFiltro2 = ''; referenciaCajaFiltro = ''; tipoCajaFiltro = ''; montoMax = ''; montoMax2 = ''; montoMin = ''; montoMin2 = ''; actualizarFiltros()" >Borar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="exportarCaja" >Descargar</button>
            </div>
        </div> -->
        <ModalCustom
            :bulModal="bulEditaCaja"
            :exitModal='() => bulEditaCaja = false'
            :widthCard="'300px'"
        >
            <FormCustom
                textSecButton="Editar"
                typeStyle="column"
                :isOneButton="true"
                :objectForm="[
                    {
                        label: 'Caja Seleccionada',
                        type: 'text',
                        name: 'nuevoNombreCaja',
                        valueDefault: memoriaCaja.Nombre_caja,
                        required: true,
                    }
                ]"
                :buttons="[,
                    {
                        text: 'Editar',
                        style: {
                            marginTop: '-40px',
                            width: '150px',
                        }
                    }
                ]"
                :actionSecButton="(data)=>{
                    editarCaja({...memoriaCaja, data1: data.nuevoNombreCaja})
                    bulEditaCaja = false
                }"
            />
        </ModalCustom>    
        <ModalCustom
            :bulModal="tab1['Exportar']"
            :exitModal='() => bulResetTab1 = true'
            topModal="100px"
            :widthCard="'80%'"
            title="Exportar"
        >
            <div class="container-form-export">
                <FormCustom
                    typeContainer="space"
                    typeStyle="column"
                    textSecButton="Exportar"
                    :actionSecButton="(data) => exportarInformacion(data)"
                    :dataRefForm="Object.entries(this.$refs)"
                    :limitInputsInSection="3"
                    :objectForm="
                        [
                            {
                                type: 'text',
                                label: 'Nombre de archivo',
                                name: 'nombreArchivo',
                                widthComplete: true,
                                width: `50%`,
                            },
                            {
                                type: 'select',
                                label: 'Formato',
                                name: 'formato',
                                widthComplete: true,
                                width: `50%`,
                                array: arregloFormatos,
                                
                            },
                            {
                                type: 'radio',
                                name: 'radio',
                                width: `290px`,
                                style: 'checkbox',
                                widthComplete: true,
                                firstSelect: true,
                                array: ['Movimientos caja','Cajas'],
                                vmodel: (data) => optionExport = data
                            },
                            {
                                type: 'custom',
                                widthComplete: false,
                                name: 'exportFilterContainer'
                            }
                        ]
                    "
                >
                    <template v-slot:exportFilterContainer>
                        <FilterExport
                            :boolVisible="optionExport === 'Movimientos caja'"
                            :dataSourceFilter="movimientosCajasFiltros"
                            :vModel="(data) => filterObject = data"
                            :stateData="{
                                data: 'movimientosCajas', 
                                dataFilterSet: 'SET_MOVIENTOS_CAJAS_FILTROS', 
                            }"
                            :arrayNameColumns="[
                                {name:'Referencias', reference: 'Referencia', type: 'text'},
                                {name:'Montos', reference: 'Cantidad', type: 'number'},
                                {name:'Ent / Sal', reference: 'Tipo_movimiento_caja', type: 'text'},
                                {name:'Nombre de las cajas', reference: 'Nombre_caja', type: 'text'},
                                {name:'Fechas', reference: 'Fecha_creacion', type: 'date'},
                                {name:'Codigos contables', reference: 'Cc_caja', type: 'text'},
                                {name:'Concepto', reference: 'Concepto', type: 'text'},
                            ]"
                        />
                        <FilterExport
                            :boolVisible="optionExport === 'Cajas'"
                            :dataSourceFilter="cajasFiltros"
                            :vModel="(data) => filterObject = data"
                            :stateData="{
                                data: 'cajas', 
                                dataFilterSet: 'SET_CAJAS_FILTROS', 
                            }"
                            :arrayNameColumns="[
                                {name:'Nombre', reference: 'Nombre_caja', type: 'text', width: 200},
                                {name:'Montos', reference: 'Cc_caja', functionData: saldoDeLaCaja, type: 'number', width: 200},
                                {name:'Codigos contables', reference: 'Cc_caja' , type: 'text', width: 200},
                            ]"
                        />
                    </template>
                </FormCustom>
            </div>
            <!-- <div class="contenedor-seguro-boton d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="eliminarMovimientosCaja()">Eliminar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="this.$store.commit('SET_BUL_MODAL', false); bulSeguroEliminar = false;" >Cancelar</button>
            </div> -->
        </ModalCustom>
        <ModalCustom
            :bulModal="bulQuestionDelete"
            :exitModal='() => bulQuestionDelete = false'
            widthCard="600px"
            title="¿Seguro que quieres eliminar este movimiento conjunto con sus contramovimientos?"
        >
            <div class="contenedor-seguro-boton2 d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="()=>{
                    eliminarMovimientosCaja()
                    bulQuestionDelete = false
                }">
                    Eliminar
                </button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulQuestionDelete = false" >Cancelar</button>
            </div>
        </ModalCustom>      
    </div>
    <div class="contenedor-modal" style="display:none;">
        <div class="x-nuevo" @click=" this.$store.commit('SET_BUL_MODAL', true); bulEditaCodigo = false; bulEditaCaja = false; cambioCodigo1 = ''; cambioCodigo2 = ''; idCodigo = ''; bulContraCaja = true" v-if="bulEditaCodigo === true"></div>
        <div class="x-nuevo" @click=" this.$store.commit('SET_BUL_MODAL', false); bulEditaCodigo = false; bulEditaCaja = false; cambioCodigo1 = ''; cambioCodigo2 = ''; idCodigo = ''; bulContraCaja = true" v-if="bulEditaCaja === true"></div>
        <!-- Interfaz mensaje quieres trabajar sin internet -->
        <div class="card-eliminarSeguro col-6 d-flex flex-column justify-content-center align-items-center" v-if="bulMensajeSinInternet === true" :style="`position:relative; 300px; margin-top:25vh; border-radius:16px 16px 16px 16px;`">
            <p class="letrasSeleccionCodigoContable" style="margin-top:50px; width:75%;">Parece que te quedaste sin conexion ¿Quieres trabajar en el modo sin internet?</p>
            <div class="contenedor-seguro-boton d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulSistemaSinInternet = true">Si</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="this.$store.commit('SET_BUL_MODAL', false); bulMensajeSinInternet = false;" >Cancelar</button>
            </div>
        </div>
        <!-- Interfaz para modificar cajas y codigos contables -->
        <div class="col-10 col-sm-6 d-flex flex-column justify-content-center align-items-center" style="position:relative; height:100vh;" v-if="bulEditaCodigo === true">
            <div class="d-flex flex-row" style="position:relative;">
                <input type="text" class="inputCodigoContable1" v-model="cambioCodigo1" />
                <input type="text" class="inputCodigoContable2" v-model="cambioCodigo2" />
                <div class="cuadro-de-color" :style="`${colorSeleccionadoCodigo2()}`" @mouseover="relojColor2('poner');" @mouseleave="relojColor2('quitar')"></div>
            </div>
            <div class="contenedor-caja-color">
                <div class="caja-color-codigos-contables2 d-flex flex-column justify-content-center align-items-around" v-if="bulCambioColor2 === true" @mouseover="relojColor2('poner');" @mouseleave="relojColor2('quitar')">
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor2 = 'encabezado'"><div class="caja-green"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Encabezado</div></div>
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor2 = 'titulo'"><div class="caja-gray"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Titulo</div></div>
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor2 = 'codigo'"><div class="caja-white"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Codigo</div></div>
                </div>
            </div>
            <button class="button-exportar d-flex justify-content-center align-items-center" @click="editarCodigoContable" style="width:500px;">Guardar cambios</button>
        </div>
        <div class="col-10 col-sm-6 d-flex flex-column justify-content-center align-items-center" style="position:relative; height:100vh;" v-if="bulEditaCaja === true">
            <div class="d-flex flex-row" style="position:relative;">
                <input type="text" class="inputCodigoContable1" v-model="codigoCaja" />
                <input type="text" class="inputCodigoContable2" v-model="cambioCaja1" />
            </div>
            <button class="button-exportar d-flex justify-content-center align-items-center" @click="editarCaja">Guardar cambios</button>
        </div>
        <!-- Interfaz para eliminar movimientos -->
        <div class="card-eliminarSeguro col-6 d-flex flex-column justify-content-center align-items-center" v-if="bulSeguroEliminar === true" :style="`position:relative; 300px; margin-top:25vh; border-radius:16px 16px 16px 16px;`">
            <p class="letrasSeleccionCodigoContable" style="margin-top:50px; width:75%;">¿Seguro que quieres eliminar este movimiento conjunto con sus contramovimientos?</p>
        </div>
        <!-- Interfaz de contrapartida al momento de crear un movimiento y declarar el ingreso o egreso -->
        <div class="card-contraPartida d-flex flex-column justify-content-center align-items-center" :style="`position:relative; height:calc(700px); margin-top:50px; border-radius:16px 16px 0px 0px;`" ref="CONTRAMOVIMIENTO">
            <h1 class="texto-contra" style="text-align:center;">Contra partida, {{nuevoMovimientoMemo.Tipo === "Salida" ? "Entrada" : "Salida"}}</h1>
            <h1 :class="colorTextoContraMonto(nuevoMovimientoMemo.Monto-montoContra-montoFinal)">{{nuevoMovimientoMemo.Monto - montoContra - montoFinal}}</h1>
            <div class="d-flex flex-wrap flex-column flex-lg-row align-items-center justify-content-lg-around margin-responsive" style="width:100%;">
                <div class="col-11 col-sm-8 col-lg-5" style="position:relative; height:60px; margin-bottom:40px;" @mouseover="bulCodigoSobre = true" @mouseleave="bulCodigoSobre = false" @click="bulCodigoSobre = true" @blur="bulCodigoSobre = false" >
                    <label for="codigoContableCaja" class="label">Código contable</label>
                    <div class="d-flex flex-row" style="position:relative; height:35px;">
                        <input type="text" id="codigoContableCaja" class="inputCodigoContable1" v-model="codigoContableCaja1" @keyup="filtrarPorBusquedaCodigoContable" />
                        <input type="text" id="codigoContableCaja" class="inputCodigoContable2" v-model="codigoContableCaja2" @keyup="filtrarPorBusquedaCodigoContable"/>
                        <div class="cuadro-de-color" :style="`${colorSeleccionadoCodigo()}`" @mouseover="relojColor('poner');" @mouseleave="relojColor('quitar')" @click="bulCodigoSobre = relojColor('poner');" @blur="bulCodigoSobre = relojColor('quitar')" ></div>
                        <div class="sumar-otro-codigo-contable" @click="agregarCodigoContable"></div>
                    </div>
                    <div class="caja-codigos-contables d-flex flex-column" v-if="bulCodigoSobre === true">
                        <div :class='`seleccionCodigoContable d-flex flex-row ${codigosContables.Tipo_codigo}`' v-for="codigosContables in codigosContablesFiltros" v-bind:key="codigosContables.id" @click="codigoContableCaja1 = codigosContables.Codigo; codigoContableCaja2 = codigosContables.Referencia; tipoRelacionCaja = codigosContables.Relacion_codigo">
                            <div class="letrasSeleccionCodigoContable" style="width:20%; overflow:hidden;">{{codigosContables.Codigo}}</div>
                            <div class="letrasSeleccionCodigoContable" style="width:60%; overflow:hidden;">{{codigosContables.Referencia}}</div>
                            <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="editarSeleccionCodigoContable" @click=" this.$store.commit('SET_BUL_MODAL', true); bulEditaCodigo = true; bulContraCaja = false; codigoMemoria = codigosContables.Referencia; relacionCodigoMemoria = codigosContables.Relacion_codigo; cambioCodigo1 = codigosContables.Codigo; cambioCodigo2 = codigosContables.Referencia; idCodigo = codigosContables.Id_cc; cambioCodigo1Memoria = codigosContables.Codigo;"></div></div>
                            <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="borrarSeleccionCodigoContable" @click="eliminarCodigoContable(codigosContables.Id_cc,codigosContables.Codigo,codigosContables.Referencia,codigosContables.Relacion_codigo)"></div></div>
                        </div>
                    </div>
                    <div class="caja-color-codigos-contables d-flex flex-column justify-content-center align-items-around" v-if="bulCambioColor === true" @mouseover="relojColor('poner');" @mouseleave="relojColor('quitar')">
                        <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'encabezado'"><div class="caja-green"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Encabezado</div></div>
                        <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'titulo'"><div class="caja-gray"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Titulo</div></div>
                        <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'codigo'"><div class="caja-white"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Codigo</div></div>
                    </div>
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="montoContra" class="label">Monto</label>
                    <input type="text" id="montoContra" class="input3" v-model="montoContra"/>
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="conceptoContra" class="label">Concepto</label>
                    <input type="text" id="conceptoContra" class="input3" v-model="conceptoContra"/>
                </div>
            </div>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="agregarContraMovimiento">Agregar</button>
            </div>
            <div class="card-despues-contra-izquierda"></div>
            <div class="contenedor-contra-movimientos d-flex align-items-center flex-column">
                <div class='contra-movimiento-registro d-flex justify-content-center flex-column flex-sm-row' v-for="informacionMemorias in informacionContraMemoria" v-bind:key="informacionMemorias.id">
                    <div class="letrasSeleccionCodigoContable w-contra-1" style="text-align:center;">{{informacionMemorias.codigo}}</div>
                    <div class="letrasSeleccionCodigoContable w-contra-2" style="text-align:center;">{{informacionMemorias.monto}}</div>
                    <div class="d-flex justify-content-center align-items-center w-contra-3"><div class="borrarSeleccionContraMovimiento" @click="borrarContraMovimiento(informacionMemorias)"></div></div>
                </div>
            </div>
            <div class="card-despues-contra-derecha"></div>
            <div class="contenedor-terminar-boton d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulContraCaja = false; this.$store.commit('SET_BUL_MODAL', false)" >Cancelar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="crearMovimientos">Listo</button>
            </div>
        </div>
        <!-- Interfaz de edicion de movimientos padres e hijos -->
        <div class="contenedor-caja-historial5 d-flex justify-content-center align-items-center flex-column" style="margin-top:75px;" v-if="bulMovimientoEditar === true">
            <h1 class="texto-contra" style="text-align:center; margin-top:-100px; margin-bottom:60px; font-weight:bold; text-align:center;">Editando movimiento seleccionado</h1>
            <div class="row d-flex justify-content-center align-items-center flex-column flex-lg-row" style="width:100%;">
                <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center flex-column">
                    <div class="ancho-responsive" style="position:relative; height:60px; margin-bottom:40px;" @mouseover="bulCajaSobre2 = true" @mouseleave="bulCajaSobre2 = false" @click="bulCajaSobre2 = true" @blur="bulCajaSobre2 = false">
                        <label for="caja" class="label">Caja seleccionada</label>
                        <div class="d-flex flex-row" style="position:relative; height:35px;">
                            <input type="text" id="caja2" class="inputCaja1" v-model="cajaSeleccionada3" @keyup="filtrarPorBusquedaCaja2" />
                            <input type="text" id="caja" class="inputCaja2" v-model="cajaSeleccionada4" @keyup="filtrarPorBusquedaCaja2"/>
                            <div class="sumar-otra-caja" @click="agregarNuevaCaja3"></div>
                        </div>
                        <div class="caja-cajasExistentes d-flex flex-column" v-if="bulCajaSobre2 === true">
                            <div class="seleccionCaja d-flex flex-row" v-for="cajaSeleccionados in cajaSeleccionadossFiltro" v-bind:key="cajaSeleccionados.id" @click="cajaSeleccionada3 = cajaSeleccionados.Codigo_caja; cajaSeleccionada4 = cajaSeleccionados.Nombre_caja; codigoDeCajaMemoria2 = cajaSeleccionados.Cc_caja;">
                                <div class="letrasSeleccionCaja" style="width:20%; overflow:hidden;">{{cajaSeleccionados.Codigo_caja}}</div>
                                <div class="letrasSeleccionCaja" style="width:60%; overflow:hidden;">{{cajaSeleccionados.Nombre_caja}}</div>
                                <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="editarSeleccionCaja" @click="this.$store.commit('SET_BUL_MODAL', true); bulEditaCaja = true; nombreMemoria = cajaSeleccionados.Nombre_caja; cambioCaja1 = cajaSeleccionados.Nombre_caja; idCaja = cajaSeleccionados.Id_caja; codigoCaja = cajaSeleccionados.Codigo_caja; ccCaja = cajaSeleccionados.Cc_caja;"></div></div>
                                <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="borrarSeleccionCaja" @click="eliminarCaja(cajaSeleccionados.Id_caja,cajaSeleccionados.Nombre_caja,cajaSeleccionados.Cc_caja)"></div></div>
                            </div>
                        </div>
                    </div>
                    <div class="ancho-responsive">
                        <label for="montoC" class="label">Monto</label>
                        <input type="text" id="montoC" class="input3" v-model="montoC2"/>
                    </div>
                </div>  
                <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center flex-column">  
                    <div class="ancho-responsive">
                        <label for="fechaContra" class="label">Fecha</label>
                        <input type="date" id="fechaContra" class="input3" v-model="fechaC2"/>
                    </div>
                    <div class="ancho-responsive">
                        <label for="referenciaC" class="label">Referencia</label>
                        <input type="text" id="referenciaC" class="input3" v-model="referenciaC2"/>
                    </div>
                </div> 
            </div> 
            <div class="ancho-responsive">
                <label for="conceptoC" class="label">Concepto</label>
                <input type="text" id="conceptoC" class="input3" v-model="conceptoC2"/>
            </div>
            <div class="col-6 d-flex flex-row justify-content-center align-items-center">
                <div class="entrada d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox d-flex justify-content-center align-items-center" @click="selectCheckbox7($event)"><div v-if="caja7 === true" class="caja"></div></div>
                    <p class="texto-check">Entrada</p>
                </div>
                <div class="salida d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox2 d-flex justify-content-center align-items-center" @click="selectCheckbox8($event)"><div v-if="caja8 === true" class="caja2"></div></div>
                    <p class="texto-check">Salida</p>
                </div>
            </div>
            <div class="contenedor-botones d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center margin-boton-exportar" @click="idInicialesEdicion = []; bulMovimientoEditar = false; this.$store.commit('SET_BUL_MODAL', true); informacionContraMemoria2 = []; montoFinal2 = 0; loadCaja()" >Cancelar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="confirmacionParaSegundoPasoMovimientoCaja2">Siguiente</button>
            </div>
        </div>
        <div class="card-contraPartida d-flex flex-column justify-content-center align-items-center" :style="`position:relative; height:calc(700px); margin-top:50px; border-radius:16px 16px 0px 0px;`" v-if="bulContraMovimientoEditar1 === true">
            <h1 v-if="caja7 === true" class="texto-contra" style="text-align:center;">Contra partida, entrada</h1>
            <h1 v-if="caja8 === true" class="texto-contra" style="text-align:center;">Contra partida, salida</h1>
            <h1 :class="colorTextoContraMonto(montoC2-montoContra2-montoFinal2)">{{montoC2-montoContra2-montoFinal2}}</h1>
            <div class="col-6 margin-izquierda" style="position:relative; height:60px; margin-bottom:40px;" @mouseover="bulCodigoSobre3 = true" @mouseleave="bulCodigoSobre3 = false" @click="bulCodigoSobre3 = true" @blur="bulCodigoSobre3 = false">
                <label for="codigoContableCaja" class="label">Código contable</label>
                <div class="d-flex flex-row" style="position:relative; height:35px;">
                    <input type="text" id="codigoContableCaja" class="inputCodigoContable1" v-model="codigoContableCaja3" @keyup="filtrarPorBusquedaCodigoContable2" />
                    <input type="text" id="codigoContableCaja" class="inputCodigoContable2" v-model="codigoContableCaja4" @keyup="filtrarPorBusquedaCodigoContable2"/>
                    <div class="cuadro-de-color" :style="`${colorSeleccionadoCodigo()}`" @mouseover="relojColor('poner');" @mouseleave="relojColor('quitar')" @click="relojColor('poner');" @blur="relojColor('quitar')"></div>
                    <div class="sumar-otro-codigo-contable" @click="agregarCodigoContable2"></div>
                </div>
                <div class="caja-codigos-contables d-flex flex-column" v-if="bulCodigoSobre3 === true">
                    <div :class='`seleccionCodigoContable d-flex flex-row ${codigosContables.Tipo_codigo}`' v-for="codigosContables in codigosContablesFiltros" v-bind:key="codigosContables.id" @click="codigoContableCaja3 = codigosContables.Codigo; codigoContableCaja4 = codigosContables.Referencia; tipoRelacionCaja2 = codigosContables.Relacion_codigo">
                        <div class="letrasSeleccionCodigoContable" style="width:20%; overflow:hidden;">{{codigosContables.Codigo}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:60%; overflow:hidden;">{{codigosContables.Referencia}}</div>
                        <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="editarSeleccionCodigoContable" @click=" this.$store.commit('SET_BUL_MODAL', true); bulEditaCodigo = true; bulContraCaja = false; codigoMemoria = codigosContables.Referencia; relacionCodigoMemoria = codigosContables.Relacion_codigo; cambioCodigo1 = codigosContables.Codigo; cambioCodigo2 = codigosContables.Referencia; idCodigo = codigosContables.Id_cc; cambioCodigo1Memoria = codigosContables.Codigo;"></div></div>
                        <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="borrarSeleccionCodigoContable" @click="eliminarCodigoContable(codigosContables.Id_cc,codigosContables.Codigo,codigosContables.Referencia,codigosContables.Relacion_codigo)"></div></div>
                    </div>
                </div>
                <div class="caja-color-codigos-contables d-flex flex-column justify-content-center align-items-around" v-if="bulCambioColor === true" @mouseover="relojColor('poner');" @mouseleave="relojColor('quitar')">
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'encabezado'"><div class="caja-green"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Encabezado</div></div>
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'titulo'"><div class="caja-gray"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Titulo</div></div>
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'codigo'"><div class="caja-white"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Codigo</div></div>
                </div>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="montoContra" class="label">Monto</label>
                <input type="text" id="montoContra" class="input3" v-model="montoContra2"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="conceptoContra" class="label">Concepto</label>
                <input type="text" id="conceptoContra" class="input3" v-model="conceptoContra2"/>
            </div>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="agregarContraMovimiento2">Agregar</button>
            </div>
            <div class="card-despues-contra-izquierda"></div>
            <div class="contenedor-contra-movimientos d-flex align-items-center flex-column">
                <div class='contra-movimiento-registro d-flex justify-content-center flex-row' v-for="informacionMemorias in informacionContraMemoria2" v-bind:key="informacionMemorias.id">
                    <div class="letrasSeleccionCodigoContable w-contra-1" style="text-align:center;">{{informacionMemorias.codigo}}</div>
                    <div class="letrasSeleccionCodigoContable w-contra-2" style="text-align:center;">{{informacionMemorias.monto}}</div>
                    <div class="d-flex justify-content-center align-items-center w-contra-3"><div class="borrarSeleccionContraMovimiento" @click="borrarContraMovimiento2(informacionMemorias)"></div></div>
                </div>
            </div>
            <div class="card-despues-contra-derecha"></div>
            <div class="contenedor-terminar-boton d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulContraMovimientoEditar1 = false; this.$store.commit('SET_BUL_MODAL', true); bulMovimientoEditar = true;" >Atrás</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="editarMovimientos">Listo</button>
            </div>
        </div>
        <!-- Interfaz de edicion movimientos hijos especificos, (contrapartidas) -->
        <div class="card-contraPartida d-flex flex-column justify-content-center align-items-center" :style="`position:relative; height:calc(400px); margin-top:75px; border-radius:16px 16px 0px 0px;`" v-if="bulContraMovimientoEditar2 === true">
            <h1 class="texto-contra" style="margin-top:40px; text-align:center;">Contra partida</h1>
            <div class="col-6 margin-izquierda" style="position:relative; height:60px; margin-bottom:40px;" @mouseover="bulCodigoSobre4 = true" @mouseleave="bulCodigoSobre4 = false" @click="bulCodigoSobre4 = true" @blur="bulCodigoSobre4 = false">
                <label for="codigoContableCaja" class="label">Código contable</label>
                <div class="d-flex flex-row" style="position:relative; height:35px;">
                    <input type="text" id="codigoContableCaja" class="inputCodigoContable1" v-model="codigoContableCaja5" @keyup="filtrarPorBusquedaCodigoContable2" />
                    <input type="text" id="codigoContableCaja" class="inputCodigoContable2" v-model="codigoContableCaja6" @keyup="filtrarPorBusquedaCodigoContable2"/>
                    <div class="cuadro-de-color" :style="`${colorSeleccionadoCodigo()}`" @mouseover="relojColor('poner');" @mouseleave="relojColor('quitar')" @click="relojColor('poner');" @blur="relojColor('quitar')"></div>
                    <div class="sumar-otro-codigo-contable" @click="agregarCodigoContable"></div>
                </div>
                <div class="caja-codigos-contables d-flex flex-column" v-if="bulCodigoSobre4 === true">
                    <div :class='`seleccionCodigoContable d-flex flex-row ${codigosContables.Tipo_codigo}`' v-for="codigosContables in codigosContablesFiltros" v-bind:key="codigosContables.id" @click="codigoContableCaja1 = codigosContables.Codigo; codigoContableCaja2 = codigosContables.Referencia; tipoRelacionCaja = codigosContables.Relacion_codigo">
                        <div class="letrasSeleccionCodigoContable" style="width:20%; overflow:hidden;">{{codigosContables.Codigo}}</div>
                        <div class="letrasSeleccionCodigoContable" style="width:60%; overflow:hidden;">{{codigosContables.Referencia}}</div>
                        <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="editarSeleccionCodigoContable" @click=" this.$store.commit('SET_BUL_MODAL', true); bulEditaCodigo = true; bulContraCaja = false; codigoMemoria = codigosContables.Referencia; relacionCodigoMemoria = codigosContables.Relacion_codigo; cambioCodigo1 = codigosContables.Codigo; cambioCodigo2 = codigosContables.Referencia; idCodigo = codigosContables.Id_cc; cambioCodigo1Memoria = codigosContables.Codigo;"></div></div>
                        <div class="d-flex justify-content-center align-items-center" style="width:10%;"><div class="borrarSeleccionCodigoContable" @click="eliminarCodigoContable(codigosContables.Id_cc,codigosContables.Codigo,codigosContables.Referencia,codigosContables.Relacion_codigo)"></div></div>
                    </div>
                </div>
                <div class="caja-color-codigos-contables d-flex flex-column justify-content-center align-items-around" v-if="bulCambioColor === true" @mouseover="relojColor('poner');" @mouseleave="relojColor('quitar')">
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'encabezado'"><div class="caja-green"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Encabezado</div></div>
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'titulo'"><div class="caja-gray"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Titulo</div></div>
                    <div class="d-flex flex-row select-caja-color align-items-center" @click="tipoColor = 'codigo'"><div class="caja-white"></div><div style="font-weight:bold; margin-left:15px; cursor:pointer;">Codigo</div></div>
                </div>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="conceptoContra" class="label">Concepto</label>
                <input type="text" id="conceptoContra" class="input3" v-model="conceptoContra3"/>
            </div>
            <div class="col-6 margin-izquierda">
                <p class="texto-contra" style="margin:0px; padding:0px; font-size:16px; text-align:center;">Para editar montos o otros aspecto debes hacerlo desde la <span class="texto-contra" style="margin:0px; padding:0px; color:#297F87; font-size:16px; cursor:pointer;" @click="redireccionOperacionOrigen()">Operacion de origen </span></p>
            </div>
            <div class="contenedor-terminar-boton2 d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulContraMovimientoEditar2 = false; this.$store.commit('SET_BUL_MODAL', false)" >Cancelar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="editarMovimiento2">Listo</button>
            </div>
        </div>
    </div>
</template>

<script>
    import TabsButton from '../../../components/TabsButton.vue'
    import Table from '../../../components/Table.vue'
    import FormCustom from '../../../components/FormCustom.vue'
    import InputManagementData from "../../../components/InputManagementData.vue";
    import ModalCustom from '../../../components/ModalComponent.vue'
    import FilterExport from '../../../components/FilterExport.vue'
    import {mapState, mapActions} from "vuex"
    import axios from 'axios'
    import messagesToUser from '../../../helpers/messagesToUser.js'

    export default {
        name: "App",
        components:{
            TabsButton,
            Table,
            FormCustom,
            InputManagementData,
            ModalCustom,
            FilterExport,
        },
        data(){
            return{

                //tabs
                tab1: {},
                bulResetTab1: false,
                tab2: {},
                tab3: {},
                content1: '',
                bulQuestionDelete: false,

                //table
                stateDataTableMovimientoCaja: {
                    data: 'movimientosCajas', 
                    dataFilterSet: 'SET_MOVIENTOS_CAJAS_FILTROS', 
                },
                stateDataTableCaja: {
                    data: 'cajas', 
                    dataFilterSet: 'SET_CAJAS_FILTROS', 
                },

                //export
                optionExport: 'Movimientos caja',
                filterObject: [],

                //variables necesarias para el funcionamiento de mostrarios en dispositivo movil
                detectorTouch: false,
                primerValorMovimientoX: 0,
                primerValorMovimientoY: 0,
                recordatorioMovimientoScroll: '',
                numeroAumentadorScroll: 0,
                memoriaLeft: 0,

                //animacion scroll
                numeroMarginLeftScroll: 0,
                numeroMarginTopScroll: 0,
                arregloDeMensaje: this.arregloDeMensaje = [],

                //optimizador de funciones
                numeroCierre: 0,
                contadorNumeroCierre: 0,

                //fondo
                alturaFondoDegradado: `alturaContenedor1`,
                controlFondoMovimientos: ``,

                //deplegable filtro y variables filtro
                izquierdaDesplegable: 50,
                derechaDesplegable: -50,
                medidaBorde:10,
                alturaFiltro: `contenedor-altura2`,
                paddingTop: 0,
                desde:'',
                hasta:'',
                nombreCajaFiltro: '',
                nombreCajaFiltro2: '',
                cajaFiltross: this.cajaFiltross = [],
                cajaAyuda: [],
                montoMin:'',
                montoMax:'',
                montoMin2:'',
                montoMax2:'',
                caja3: true,
                caja4: false,
                movimientosBancos: this.movimientosBancos = [],
                codigoCajaFiltro: '',
                codigoCajaFiltro2: '',
                referenciaCajaFiltro: '',
                tipoCajaFiltro: '',
                tipoCajaFiltros: this.tipoCajaFiltros = ['entrada','salida'],

                //interfaz de agregar nuevo movimiento
                caja1: false,
                caja2: false,
                caja6: false,
                montoC: '',
                bulCajaSobre: false,
                cajaSeleccionada1: '',
                cajaSeleccionada2: '',
                cajaSeleccionadoss: this.cajaSeleccionadoss = [],
                cajaSeleccionadossFiltro: this.cajaSeleccionadossFiltro = [],
                ultimoCodigoCajaCreado: '',
                codigoCaja: '',
                cajaMovimiento: false,
                fechaVVenta: '',
                bulContraCaja: false,
                conceptoC: '',
                referenciaC: '', 
                fechaC: '',
                montoFinal: 0,
                codigoDeCajaMemoria: '',
                arregloTodosLosMovimientos: this.arregloTodosLosMovimientos = [],

                //interfaz de contra movimiento
                montoContra: '',
                conceptoContra: '',
                codigoContableCaja1: '',
                codigoContableCaja2: '',
                bulCodigoSobre: false,
                bulCambioColor: false,
                relojCodigo: '',
                tipoColor: 'codigo',
                informacionContraMemoria: this.informacionContraMemoria = [],
                bulCodigosMemoria: false,
                tipoDeContraMovimiento: '',
                tipoRelacionCaja: '',

                //interfaz agregar nueva caja
                nombreDeLaCaja: '',
                nuevoCodigoContable1: '',
                nuevoCodigoContable2: '',
                bulCodigoSobre2: false,

                //interfaz de exportar
                filtradoCaja: '',
                seleccionFiltro: this.seleccionFiltro = [],
                formatoS: '',
                bulFiltro: false,
                nombreDelArchivo: '',
                arregloFormatos: this.arregloFormatos = [
                    "XLSX (Excel)",
                    "PDF",
                ],
                cajaIdsFiltro: this.cajaIdsFiltro = [],
                movimientoIdsFiltro: this.movimientoIdsFiltro = [],

                //interfaz de cambio de codigo de gestion contable y caja
                bulEditaCodigo: '',
                cambioCodigo1: '',
                cambioCodigo1Memoria: '',
                cambioCodigo2: '',
                idCodigo: '',
                bulEditaCaja: false,
                cambioCaja1: '',
                idCaja: '',
                ccCaja: '',
                nombreMemoria: '',
                codigoMemoria: '',
                relacionCodigoMemoria: '',
                bulCambioColor2: false,
                relojCodigo2: '',
                tipoColor2: 'codigo',
                memoriaCaja: {},

                //interfaz de eliminar movimientos caja
                relacionMovimiento: '',
                bulSeguroEliminar: false,

                //interfaz editar contraMovimiento
                bulContraMovimientoEditar1: false,
                montoContra2: '',
                montoFinal2: 0,
                codigoContableCaja3: '',
                codigoContableCaja4: '',
                conceptoContra2: '',
                informacionContraMemoria2: this.informacionContraMemoria2 = [],
                tipoRelacionCaja2: '',  
                tipoDeContraMovimiento2: '',
                informacionEdicion: '',
                idInicialesEdicion: this.idInicialesEdicion = [],
                cargaMovimientosOtros: this.cargaMovimientosOtros = [],
                relacionIdPadre: '',
                bulCodigoSobre3: false,

                //interfaz editar movimiento
                bulMovimientoEditar: false,
                codigoDeCajaMemoria2: '',
                bulCajaSobre2: false,
                arregloTodosLosMovimientos2: this.arregloTodosLosMovimientos2 = [], 
                montoC2: '',
                fechaC2: '',
                referenciaC2: '',
                conceptoC2: '',
                caja7: false,
                caja8: false,
                cajaSeleccionada3: '',
                cajaSeleccionada4: '',

                //interfaz editar contramovimiento solamente
                bulContraMovimientoEditar2: false,
                montoContra3: '',
                codigoContableCaja5: '',
                codigoContableCaja6: '',
                conceptoContra3: '',
                bulCodigoSobre4: false,

                //interfaz trabajar sin internet
                bulMensajeSinInternet: false,
                bulSistemaSinInternet: false,

                bulModalEditContramovimiento: false,
                nuevoMovimientoMemo: {},
                contraMovimientosMemo: [],
                montoTotalMovimientos: 0,
                codigoDe: {
                    '1.1.1.02.': 'banco',
                    '1.1.1.01.': 'caja',
                },
                contraMovimientoSeleccionado: {
                    Codigo_contable: {
                        color: "",
                        Codigo: "",
                        Referencia: ""
                    },
                    Monto: 0,
                    Seguir_agregando: {
                        "Sumar Contramovimiento": false
                    },
                    Nombre: ""
                },
                reiniciarFormNuevaEntrada: false,
                reiniciarFormAgregarCaja: false,
                reiniciarFormExportar: false,
            }
        },
        created () {
            this.loadCaja();
        },
        computed:{
            ...mapState([
                "bulModal",
                "cajas",
                "cajasFiltros",
                "codigosContablesFiltros",
                "movimientosBancos",
                "movimientosCajas",
                "movimientosCajasFiltros",
                "movimientosOtros",
                "server",
                "statusApiCaja",
                "statusApiCodigoC",
                "ultimoCodigoContableCaja",
            ]),
        },
        mounted() {
            this.requestMovimientosCajas();
            this.requestCodigosContables();
            this.requestCajas();
            this.content1 = this.$refs.PARRAFFORM
        },
        methods:{
            ...mapActions([
                "activeMessageCorrect",
                "activeMessageError",
                "addMovimientosCajas",
                "crearCajaGestion",
                "crearCodigoContable",
                "editarCajaVuex",
                "editarCodigoContableVuex",
                "eliminarCajaVuex",
                "eliminarCodigoContableVuex",
                "exportData",
                "requestCajas",
                "requestCodigosContables",
                "requestMovimientosBancos",
                "requestMovimientosCajas",
                "requestMovimientosOtros",
                "resetStatusApiCaja",
                "resetStatusApiCodigoC",
                "setUltimoCodigoContableCaja",
                "someFieldEmpty",
                "tableSearchAction",
            ]),

            //funcion que carga informacion de la gestion a caja

            changeAndBackBoolWithDelay(element, boolFirstTime, time){
                this[element] = !boolFirstTime
                const delay = setTimeout(()=>{
                    this[element] = boolFirstTime
                    clearTimeout(delay)
                }, time)
            },

            async loadCaja(){
                //consulta de apirest
                await this.requestCajas()
                await this.requestMovimientosBancos()
                await this.requestMovimientosOtros()
                await this.setUltimoCodigoContableCaja()

                this.cajaSeleccionadoss = this.cajas
                this.cajaSeleccionadossFiltro = this.cajas
                this.cajaFiltross = this.cajas                
                this.movimientosBancos = this.movimientosBancos
                this.cargaMovimientosOtros = this.movimientosOtros
                this.numeroCierre = (this.movimientosCajasFiltros.length * 3) + this.cajaFiltross.length
            },

            //funcion para agregar un nuevo codigo contable

            async agregarCodigoContable(data){
                const {color,Codigo,Referencia} = data

                //calculando puntos de codigo
                let textoLista = Codigo.split('');
                let puntosEncontrados = textoLista.filter((puntoH) => {return puntoH === '.'})

                //control de errores
                if(Codigo === '' || Referencia === ''){
                    this.activeMessageError('porfavor rellena los campos para crear un nuevo codigo contable')
                    return
                }

                if(Referencia.length > 30){
                    this.activeMessageError('nombre codigo no puede exceder los 30 caracteres')
                    return
                }

                if(color === 'encabezado' && puntosEncontrados.length > 0){
                    this.activeMessageError('Los encabezados se deben crear con un solo punto')
                    return
                }

                const object = {
                    codigo: Codigo,
                    nombre: Referencia,
                    tipoCodigo: color,
                    relacionCodigo: 'caja'
                }

                //agregando el codigo contable

                await this.crearCodigoContable(object)
            },

            //editar un codigo contable

            async editarCodigoContable(data){
                const {data1, data2, data3, Id_cc} = data
      
                //calculando puntos de codigo
                let textoLista = data1.split('');
                let puntosEncontrados = textoLista.filter((puntoH) => {return puntoH === '.'})

                //revisando que no se creen encabezados desordenados
                if(data3 === 'encabezado' && puntosEncontrados.length > 0){
                    this.activeMessageError('Los encabezados se deben crear con un solo punto')
                    return
                }

                const object = {
                    codigo: data1,
                    nombre: data2,
                    tipoCodigo: data3,
                    idCodigo: Id_cc,
                }

                //consulta de la apirest
                await this.editarCodigoContableVuex(object)
            },

            //eliminar un codigo contable

            async eliminarCodigoContable(data){
                const {Id_cc, Codigo, Referencia, Relacion_codigo} = data

                const object = {
                    idCodigo: Id_cc,
                    codigoContable: Codigo,
                    nombre: Referencia,
                    relacion: Relacion_codigo,
                }

                //eliminando el codigo contable
                await this.eliminarCodigoContableVuex(object)
            },

            //crear nueva caja

            async agregarNuevaCaja(nombre,bul){

                //los campos no pueden estar vacios
                if(nombre === ''){
                    this.activeMessageError(messagesToUser.camposVacios)
                    return
                }

                //nombre de la caja no exceda de 30 caracteres
                if(nombre.length > 30){
                    this.activeMessageError(messagesToUser.limiteCaracteresExcedido)
                    return
                }

                //agregando el codigo contable
                const codigoContableData = {
                    codigo: this.ultimoCodigoContableCaja,
                    nombre: nombre,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2'),
                    tipoCodigo: 'codigo',
                    relacionCodigo: 'caja'
                }

                const status = await this.crearCodigoContable(codigoContableData)         

                if(status === "ok"){
                    //creando la caja
                    await this.crearCajaGestion({
                        nombre: nombre,
                        codigoContable: this.ultimoCodigoContableCaja,
                        token: localStorage.getItem('token'),
                        tokenGestion: localStorage.getItem('token2')
                    })

                    // reiniciamos inputs
                    // parametros: elemento, buleano en su estado inicial, tiempo de delay
                    this.changeAndBackBoolWithDelay("reiniciarFormAgregarCaja", this.reiniciarFormNuevaEntrada, 100)

                    if(bul){
                        this.bulResetTab1 = true
                    }                  
                }
            },

            //editar un codigo contable

            async editarCaja(data){

                const {Nombre_caja, Codigo_caja, Id_caja, Cc_caja, data1} = data

                //nombre de la caja no exceda de 30 caracteres
                if(Nombre_caja.length > 30){
                    this.activeMessageError(messagesToUser.limiteCaracteresExcedido)
                    return
                }

                const object = {
                    codigo: Codigo_caja,
                    nombre: data1,
                    nombreMemoria: Nombre_caja,
                    codigoMemoria: Cc_caja,
                    idCodigo: Id_caja,
                }

                //consulta de la apirest
                await this.editarCajaVuex(object)
            },

            //eliminar una caja

            async eliminarCaja(data){
                //id,nombre,cc
                const {Id_caja, Nombre_caja, Cc_caja} = data

                const object = {
                    idCodigo: Id_caja,
                    codigo: Cc_caja,
                    nombre: Nombre_caja,
                }

                //eliminando la caja
                await this.eliminarCajaVuex(object)

            },

            //funcion que filtra las cajas que se parecen a los datos proporcionado en el input

            async filtrarPorBusquedaCaja(){
                //consulta de apirest
                let filtradoCajaGestion = await axios.get(`${this.server}/information/filtradoCajaGestion`, {

                    params: {token: localStorage.getItem('token'),tokenGestion: localStorage.getItem('token2'),codigo: this.cajaSeleccionada1, nombre: this.cajaSeleccionada2}}, 

                    {headers: {'Content-Type': 'application/json'}
                });

                //token expiro
                if(filtradoCajaGestion.data.message === "Error en validación"){
                    this.$router.push({path: '/app/profile'})
                }
                
                this.cajaSeleccionadossFiltro = [...filtradoCajaGestion.data.cajasFiltradas]

            },

            // nuevo agregar movimiento
            async agregarMovimiento(data){
                const {Caja_seleccionada, Concepto, Fecha, Monto, Referencia, Tipo} = data
                const {Nombre_caja = "", Cc_caja = "", color} = Caja_seleccionada
                const inputsVacios = await this.someFieldEmpty({
                    fields:{ Cc_caja, Nombre_caja, Concepto, Fecha, Monto, Referencia }
                })

                if(Caja_seleccionada === "" || Cc_caja === ""){
                    this.activeMessageError("Por favor seleccione una caja.")
                    return
                }
                if(inputsVacios) {
                    this.activeMessageError("Rellena todas las celdas.")
                    return
                }
                if(Monto <= 0){
                    this.activeMessageError("El monto no puede ser 0 o menos.")
                    return
                }
                if(Tipo === "Salida"){
                    const saldoDeLaCaja = this.saldoDeLaCaja(Cc_caja)
                    const saldoInsuficiente = saldoDeLaCaja - Monto < 0
                    
                    if(saldoInsuficiente){
                        this.activeMessageError("La caja no cuenta con saldo suficiente.")
                        return
                    }
                }
                
                this.montoTotalMovimientos += Monto
                this.nuevoMovimientoMemo = data
                this.bulContraCaja = true
            },

            async agregarNuevoContraMovimiento(data){
                const {Monto_total, Monto, Contramovimientos, Seguir_agregando, Codigo_contable} = data
                const {Codigo = "", Referencia = ""} = Codigo_contable
                const inputsVacios = await this.someFieldEmpty({fields:{Referencia, Codigo}})
                const Tipo = this.nuevoMovimientoMemo.Tipo === "Entrada" ? "Salida" : "Entrada"
                const codigoIdentificatorio = Codigo.substring(0,9)

                if(inputsVacios) {
                    this.activeMessageError("Rellena todas las celdas.")
                    return
                }
                if(Monto <= 0){
                    this.activeMessageError("El monto no puede ser 0 o menos.")
                    return
                }
                if(Monto_total - Monto < 0){
                    this.activeMessageError("El monto total no puede ser menos de 0.")
                    return
                }
                if(Codigo === this.nuevoMovimientoMemo. Caja_seleccionada.Cc_caja){
                    this.activeMessageError("El contramovimiento no puede ser la misma caja que el movimiento principal.")
                    return
                }
                if(Tipo === "Salida" && this.codigoDe[codigoIdentificatorio] === "caja"){
                    const saldoDeLaCaja = this.saldoDeLaCaja(Codigo)
                    const saldoInsuficiente = saldoDeLaCaja - Monto < 0
                    
                    if(saldoInsuficiente){
                        this.activeMessageError("La caja no cuenta con saldo suficiente.")
                        return
                    }
                }
                
                this.contraMovimientosMemo.push({...data, Nombre: Referencia})
                this.montoTotalMovimientos -= Monto
                window.scrollTo(0,0)

                if(Seguir_agregando['Sumar Contramovimiento'] === false){
                    this.crearMovimientos({dataMovimiento: this.nuevoMovimientoMemo, dataArrayContraMovimientos: this.contraMovimientosMemo})

                    // reiniciamos elementos
                    // parametros: elemento, buleano en su estado inicial, tiempo de delay
                    this.changeAndBackBoolWithDelay("reiniciarFormNuevaEntrada", this.reiniciarFormNuevaEntrada, 100)
                    this.bulContraCaja = false
                    this.bulResetTab1 = true
                }
            },

            //saber el saldo que tiene una caja 

            saldoDeLaCaja(codigoSeleccionado){
                //no se pede sacar dinero que no esta agregado en una caja existente
                let cantidadDeDineroCaja = 0
                let filterMovimientoCajaSalida = this.movimientosCajas.filter(cajasMovimiento => {
                    return cajasMovimiento.Cc_caja === codigoSeleccionado && cajasMovimiento.Tipo_movimiento_caja.toLowerCase() === 'salida'
                })
                let filterMovimientoCajaEntrada = this.movimientosCajas.filter(cajasMovimiento => {
                    return cajasMovimiento.Cc_caja === codigoSeleccionado && cajasMovimiento.Tipo_movimiento_caja.toLowerCase() === 'entrada'
                })

                for(let i = 0; i < filterMovimientoCajaSalida.length; i++){
                    cantidadDeDineroCaja = cantidadDeDineroCaja - filterMovimientoCajaSalida[i].Cantidad
                }
                for(let i = 0; i < filterMovimientoCajaEntrada.length; i++){
                    cantidadDeDineroCaja = cantidadDeDineroCaja + filterMovimientoCajaEntrada[i].Cantidad
                }

                return cantidadDeDineroCaja
            },


            //eliminar contra movimiento

            borrarContraMovimiento(contramovimientoABorrar){
                const nuevoContraMovimientosMemo = this.contraMovimientosMemo
                    .filter((contraMovimiento) => {
                        const {Codigo} = contraMovimiento.Codigo_contable
                        const CodigoDeContraABorrar = contramovimientoABorrar.Codigo_contable.Codigo
                        return Codigo !== CodigoDeContraABorrar
                    })

                this.contraMovimientosMemo = nuevoContraMovimientosMemo
                this.montoTotalMovimientos += contramovimientoABorrar.Monto
                return
            },
            editarContraMovimiento(contraMovimientoAEditar, montoNuevo){
                const nuevoContraMovimientosMemo = this.contraMovimientosMemo
                    .map((contraMovimiento)=>{
                        const {Codigo} = contraMovimiento.Codigo_contable
                        const CodigoDeContraAEditar = contraMovimientoAEditar.Codigo_contable.Codigo

                        if(Codigo === CodigoDeContraAEditar){
                            return {...contraMovimiento, Monto: montoNuevo}
                        }else{
                            return contraMovimiento
                        }
                    })

                this.contraMovimientosMemo = nuevoContraMovimientosMemo
                this.montoTotalMovimientos += contraMovimientoAEditar.Monto - montoNuevo
                return
            },

            //eliminar contra movimiento

            borrarContraMovimiento2(objetivo){
                for(let i = 0; i < this.informacionContraMemoria2.length; i++){
                    if(this.informacionContraMemoria2[i].monto === objetivo.monto && this.informacionContraMemoria2[i].codigo === objetivo.codigo && this.informacionContraMemoria2[i].concepto === objetivo.concepto){
                        this.montoFinal2 = this.montoFinal2 - Number(this.informacionContraMemoria2[i].monto)
                        this.informacionContraMemoria2.splice(i,1)
                    }
                }
            },
            

            //crear un nuevo movimiento caja paso final

            async crearMovimientos(data){
                const {dataMovimiento, dataArrayContraMovimientos} = data
                
                //ver que el monto ya haya sido llevado a 0
                if(this.montoTotalMovimientos !== 0){
                    this.activeMessageError('lleva el saldo a 0 agregando los contramovimientos que veas pertinentes')
                    this.bulContraCaja = false;
                    setTimeout(() => {
                        this.bulContraCaja = true;
                    }, this.duracionError);
                    return
                }

                this.arregloTodosLosMovimientos = []

                this.arregloTodosLosMovimientos.push({
                    cantidad: dataMovimiento.Monto, 
                    fecha: dataMovimiento.Fecha,
                    movimiento: dataMovimiento.Tipo, 
                    nombre: dataMovimiento.Caja_seleccionada.Nombre_caja, 
                    codigoContable: dataMovimiento.Caja_seleccionada.Cc_caja, 
                    concepto: dataMovimiento.Concepto, 
                    tipo: "caja", 
                    referencia: dataMovimiento.Referencia, 
                    contra: false
                })

                for(let i = 0; i < dataArrayContraMovimientos.length; i++){
                    const contraMovimiento = dataArrayContraMovimientos[i]
                    const {Codigo, Referencia} = contraMovimiento.Codigo_contable
                    let tipoContraMovimiento = this.codigoDe[Codigo.substring(0,9)] || "otro"

                    this.arregloTodosLosMovimientos.push({
                        cantidad: contraMovimiento.Monto, 
                        fecha: dataMovimiento.Fecha, 
                        movimiento: dataMovimiento.Tipo === "Salida" ? "Entrada" : "Salida", 
                        nombre: Referencia, 
                        codigoContable: Codigo,
                        concepto: dataMovimiento.Concepto,
                        tipo: tipoContraMovimiento, 
                        referencia: dataMovimiento.Referencia, 
                        contra: true
                    })
                }

                //creando los movimientos
                let movimientosCaja = await axios.post(`${this.server}/information/crearMovimientoCaja`, {
                    arregloInformacion: this.arregloTodosLosMovimientos,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })

                if(movimientosCaja.data.message === 'movimientos creados'){
                    //caja creada con exito
                    this.activeMessageCorrect('movimientos creados')

                    //resetiando monto comprobacion
                    let contador = this.informacionContraMemoria.length

                    for(let i = 0; i < contador; i++){
                        this.montoFinal = this.montoFinal - Number(this.informacionContraMemoria[0].monto)
                        this.informacionContraMemoria.splice(0,1)
                    }

                    //reseiando todos los campos si es necesario
                    if(this.caja6 === false){
                        this.cajaSeleccionada1 = ''
                        this.cajaSeleccionada2 = ''
                        this.montoC = ''
                        this.fechaC = ''
                        this.referenciaC = ''
                        this.conceptoC = ''
                        this.caja1 = false
                        this.caja2 = false
                        this.montoContra = ''
                        this.codigoContableCaja1 = ''
                        this.codigoContableCaja2 = ''
                        this.conceptoContra = ''
                    }
                    this.bulContraCaja = false;
                    setTimeout(() => {
                        this.bulContraCaja = false;
                    }, this.duracionError);
                }
                if(movimientosCaja.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulContraCaja = false;
                    setTimeout(() => {
                        this.bulContraCaja = true;
                    }, this.duracionError);
                    return
                }
            },

            //editar un  movimiento caja

            async editarMovimientos(){
                //variables con respecto a entrada o salida de los movimientos
                    //movimiento
                let tipo1
                    //opuesto contra movimiento
                let tipo2

                if(this.caja7 === true){
                    tipo1 = "entrada"
                    tipo2 = "salida"
                }
                if(this.caja8 === true){
                    tipo1 = "salida"
                    tipo2 = "entrada"
                }

                //ver que el monto ya haya sido llevado a 0
                if((this.montoC2 - this.montoFinal2) !== 0){
                    this.activeMessageError('lleva el saldo a 0 agregando los contramoviminetos que veas pertinentes')
                    this.bulContraMovimientoEditar1 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar1 = true;
                    }, this.duracionError);
                    return
                }

                this.arregloTodosLosMovimientos2 = []

                for(let i = 0; i < (this.informacionContraMemoria2.length + 1); i++){
                    if(i === 0){
                        this.arregloTodosLosMovimientos2.push({cantidad: this.montoC2, fecha: this.fechaC2, movimiento: tipo1, nombre: this.cajaSeleccionada4, codigoContable: this.codigoDeCajaMemoria2, concepto: this.conceptoC2, tipo: "caja", referencia: this.referenciaC2, contra: false, idEdicion: this.idInicialesEdicion[0].id})
                    }else{
                        this.arregloTodosLosMovimientos2.push({cantidad: this.informacionContraMemoria2[i-1].monto, fecha: this.fechaC2, movimiento: tipo2, nombre: this.informacionContraMemoria2[i-1].nombre, codigoContable: this.informacionContraMemoria2[i-1].codigo, concepto: this.informacionContraMemoria2[i-1].concepto,tipo: this.informacionContraMemoria2[i-1].tipo, referencia: this.referenciaC2, contra: true, idEdicion: ''})
                    }
                }

                //editando los movimientos
                let movimientosCaja = await axios.post(`${this.server}/information/editarMovimientoCaja`, {
                    idsEdicion: this.idInicialesEdicion,
                    idPadre: this.relacionIdPadre,
                    arregloInformacion2: this.arregloTodosLosMovimientos2,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })

                if(movimientosCaja.data.message === 'Movimiento editado'){
                    //caja creada con exito
                    this.activeMessageCorrect('movimientos editados')
                    
                    this.cajaSeleccionada3 = ''
                    this.cajaSeleccionada4 = ''
                    this.montoC2 = ''
                    this.fechaC2 = ''
                    this.referenciaC2 = ''
                    this.conceptoC2 = ''
                    this.caja7 = false
                    this.caja8 = false
                    this.montoContra2 = ''
                    this.codigoContableCaja3 = ''
                    this.codigoContableCaja4 = ''
                    this.conceptoContra2 = ''
                    let contador = this.informacionContraMemoria2.length
                    this.idInicialesEdicion = [];

                    for(let i = 0; i < contador; i++){
                        this.montoFinal2 = this.montoFinal2 - Number(this.informacionContraMemoria2[0].monto)
                        this.informacionContraMemoria2.splice(0,1)
                    }
                    this.bulContraMovimientoEditar1 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar1 = false;
                    }, this.duracionError);
                }
                if(movimientosCaja.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulContraMovimientoEditar1 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar1 = true;
                    }, this.duracionError);
                    return
                }
            },

            //editar contramovimientos

            async editarMovimiento2(){
                if(this.conceptoContra3 === '' || this.codigoContableCaja5 === '' || this.codigoContableCaja6 === ''){
                    this.activeMessageError('Rellena los campos')
                     this.bulContraMovimientoEditar2 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar2 = true;
                    }, this.duracionError);
                    return
                }

                //creacion de movimiento a editar
                this.arregloTodosLosMovimientos2 = []
                this.arregloTodosLosMovimientos2.push({cantidad: this.informacionEdicion.Cantidad, fecha: this.informacionEdicion.Fecha_creacion, movimiento: this.informacionEdicion.Tipo_movimiento_caja, nombre: this.codigoContableCaja6, codigoContable: this.codigoContableCaja5, concepto: this.conceptoContra3, tipo: "caja", referencia: this.informacionEdicion.Referencia, contra: true, idEdicion: this.idInicialesEdicion[0].id})

                //editando el contra-movimiento
                let movimientosCaja = await axios.post(`${this.server}/information/editarMovimientoCaja`, {
                    idsEdicion: this.idInicialesEdicion,
                    idPadre: this.relacionIdPadre,
                    arregloInformacion2: this.arregloTodosLosMovimientos2,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })

                if(movimientosCaja.data.message === 'Movimiento editado'){
                    //caja creada con exito
                    this.activeMessageCorrect('movimientos editados',)
                    
                    this.codigoContableCaja5 = ''
                    this.codigoContableCaja6 = ''
                    this.conceptoContra3 = ''
                    this.idInicialesEdicion = [];
                    this.arregloTodosLosMovimientos2 = [];
                    this.relacionIdPadre = ''

                    this.bulContraMovimientoEditar2 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar2 = false;
                    }, this.duracionError);
                }
                if(movimientosCaja.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulContraMovimientoEditar2 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar2 = true;
                    }, this.duracionError);
                    return
                }
            },

            //eliminar movimientos caja

            async eliminarMovimientosCaja(){
                //eliminando la caja
                let movimientosEliminados = await axios.post(`${this.server}/information/eliminarMovimientosCaja`, {
                    codigoRelacion:this.relacionMovimiento,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })
                
                if(movimientosEliminados.data.message === 'Movimientos eliminados'){
                    //movimiento eliminado
                    this.activeMessageCorrect('movimientos eliminados')
                    this.bulSeguroEliminar = false;
                    await this.requestMovimientosCajas()
                }
                if(movimientosEliminados.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulSeguroEliminar = false;
                    setTimeout(() => {
                        this.bulSeguroEliminar = true;
                    }, this.duracionError);
                }
            },

            //exportar informacion de caja

            async exportarInformacion(data){
                const {nombreArchivo, formato, radio} = data
                let headerExport;
                let arrayExport = [];
                let numSaldo = 0;
                let widthPDF = 0;
                let heightPDF = 0;

                //inputs no vacios
                if(nombreArchivo === '' || formato === ''){
                    this.activeMessageError('porfavor rellena todos los campos para exportar la informacion', 1)
                    return
                }   

                //filtramos data
                await this.tableSearchAction({
                    value:this.filterObject, 
                    data:(radio === 'Movimientos caja') ? this.stateDataTableMovimientoCaja : this.stateDataTableCaja,
                })       


                if(radio === 'Movimientos caja'){
                    //declaramos informacion
                    this.movimientosCajasFiltros.forEach((array) => {
                        numSaldo = (array.Tipo_movimiento_caja === 'entrada') 
                            ? (numSaldo + array.Cantidad) 
                            : (numSaldo - array.Cantidad)
                            
                        arrayExport.push({
                            ...array,
                            debe: (array.Tipo_movimiento_caja === 'entrada') ? array.Cantidad : 0,
                            haber: (array.Tipo_movimiento_caja === 'salida') ? array.Cantidad : 0,
                            saldo: numSaldo
                        })
                    })

                    //declaramos header de tabla
                    headerExport = [
                        {label: "Fecha",           value: "Fecha_creacion", width: 20, widthPDF: 100},
                        {label: "Codigo contable", value: "Cc_caja",        width: 20, widthPDF: 100},
                        {label: "Referencia",      value: "Referencia",     width: 20, widthPDF: 100},
                        {label: "Concepto",        value: "Concepto",       width: 40, widthPDF: 100},
                        {label: "Caja",            value: "Nombre_caja",    width: 20, widthPDF: 100},
                        {label: "Debe",            value: "debe",           width: 20, widthPDF: 100},
                        {label: "Haber",           value: "haber",          width: 20, widthPDF: 100},
                        {label: "Saldo",           value: "saldo",          width: 20, widthPDF: 100},
                    ]

                    //declaramos el ancho de la hoja de pdf segun la informacion que le estemos mandando
                    widthPDF = 1000;
                    heightPDF = 500;
                }
                if(radio === 'Cajas'){
                    //declaramos informacion
                    this.cajasFiltros.forEach((array) => {
                        arrayExport.push({
                            ...array,
                            saldo: '0'
                        })
                    })

                    //decaramos header de tabla
                    headerExport = [
                        {label: `Nombre`,          value: "Nombre_caja", width: 20, widthPDF: 100},
                        {label: "Codigo contable", value: "Cc_caja",     width: 20, widthPDF: 100},
                        {label: "Saldo",           value: "saldo",       width: 40, widthPDF: 100},
                    ]

                    //declaramos el ancho de la hoja de pdf segun la informacion que le estemos mandando
                    widthPDF = 400;
                    heightPDF = 500;
                }

                await this.exportData({
                    informacionAExportar: JSON.stringify(arrayExport) ,
                    header: JSON.stringify(headerExport),
                    formato: formato,
                    nombre: nombreArchivo,
                    widthPDF: widthPDF,
                    heightPDF: heightPDF,
                })
            },

            //confirmacion de ver si es un contramovimiento o si el saldo se volveria menos de 0 para ver si es posible su eliminacion

            revisarPermisoDeEliminacion(data){

                const {Cantidad,Contra,Cc_caja,Codigo_de_relacion} = data

                if(Contra){
                    this.activeMessageError('no puedes eliminar un contramovimiento, tienes que editar esta contrapartida desde el movimiento principal')
                    return
                }
                if((this.saldoDeLaCaja(Cc_caja) - Cantidad) < 0){
                    this.activeMessageError('eliminar este movimiento pondria un saldo negativo en esta caja')
                    return
                }
                
                this.relacionMovimiento = Codigo_de_relacion;
                this.bulSeguroEliminar = true;

                this.bulQuestionDelete=true
            },

            //redirige hacia el movimiento de padre de un contramovimiento

            redireccionOperacionOrigen(){
                let arregloCajas = this.movimientosCajas.filter(movimiento3 =>{
                    return Number(this.informacionEdicion.Codigo_de_relacion) === Number(movimiento3.Codigo_de_relacion) && movimiento3.Contra === false
                })
                this.informacionEdicion = arregloCajas[0]
                this.conceptoContra3 = ''
                this.codigoContableCaja5 = ''
                this.codigoContableCaja6 = ''
                this.bulContraMovimientoEditar2 = false
                this.filtroDeEdicionMovimientos()
            },

            //revisa que tiene que mostrar la interfaz para editar este movimientos ademas de cargar sus datos 

            filtroDeEdicionMovimientos(data){

                const { Nombre_caja,Concepto,Cc_caja } = data

                if(data.Contra === false){
                    //carga de datos total necesaria para la edicion completa de un movimiento y sus contra movimientos
                    //carga movimiento inicial
                    this.relacionIdPadre = data.Codigo_de_relacion
                    this.idInicialesEdicion.push({id:data.Id_movimiento_caja,tipo:'caja'})
                    this.bulMovimientoEditar = true;
                    this.codigoDeCajaMemoria2 = this.cajaSeleccionadoss.filter(cajas => {
                        return cajas.Cc_caja === data.Cc_caja
                    });
                    this.codigoDeCajaMemoria2 = this.codigoDeCajaMemoria2[0].Cc_caja
                    this.montoC2 = data.Cantidad;
                    this.fechaC2 = data.Fecha_creacion;
                    this.referenciaC2 = data.Referencia;
                    this.conceptoC2 = data.Concepto;
                    if(data.Tipo_movimiento_caja === 'entrada'){
                        this.caja7 = true;
                        this.caja8 = false;
                    }
                    if(data.Tipo_movimiento_caja === 'salida'){
                        this.caja7 = false;
                        this.caja8 = true;
                    }
                    let codigoCaja = this.cajaSeleccionadoss.filter(cajas => {
                        return cajas.Cc_caja === data.Cc_caja
                    });
                    this.cajaSeleccionada3 = codigoCaja[0].Codigo_caja;
                    this.cajaSeleccionada4 = data.Nombre_caja;
                    this.$store.commit('SET_BUL_MODAL', true)

                    //carga contra movimientos
                    let arregloOtros = this.cargaMovimientosOtros.filter(movimiento => {
                        return Number(data.Codigo_de_relacion) === Number(movimiento.Codigo_de_relacion) && movimiento.Contra === true
                    })
                    
                    if(arregloOtros !== undefined){
                        for(let i = 0; i < arregloOtros.length; i++){
                            this.informacionContraMemoria2.push({monto: arregloOtros[i].Cantidad, codigo: arregloOtros[i].Cc_otro, concepto: arregloOtros[i].Concepto, nombre: arregloOtros[i].Nombre_otro, tipo: 'otro'})
                            this.idInicialesEdicion.push({id:arregloOtros[i].Id_movimiento_otro, tipo: 'otro'})
                            this.montoFinal2 = this.montoFinal2 + Number(this.informacionContraMemoria2[this.informacionContraMemoria2.length - 1].monto)
                        }  
                    }

                    let arregloBancos = this.movimientosBancos.filter(movimiento2 =>{
                        return Number(data.Codigo_de_relacion) === Number(movimiento2.Codigo_de_relacion) && movimiento2.Contra === true
                    })

                    if(arregloBancos !== undefined){
                        for(let i = 0; i < arregloBancos.length; i++){
                            this.informacionContraMemoria2.push({monto: arregloBancos[i].Cantidad, codigo: arregloBancos[i].Cc_banco, concepto: arregloBancos[i].Concepto, nombre: arregloBancos[i].Nombre_banco, tipo: 'banco'})
                            this.idInicialesEdicion.push({id:arregloBancos[i].Id_movimiento_banco, tipo: 'banco'})
                            this.montoFinal2 = this.montoFinal2 + Number(this.informacionContraMemoria2[this.informacionContraMemoria2.length - 1].monto)
                        }  
                    }

                    let arregloCajas = this.movimientosCajas.filter(movimiento3 =>{
                        return Number(data.Codigo_de_relacion) === Number(movimiento3.Codigo_de_relacion) && movimiento3.Contra === true
                    })

                    if(arregloCajas !== undefined){
                        for(let i = 0; i < arregloCajas.length; i++){
                            this.informacionContraMemoria2.push({monto: arregloCajas[i].Cantidad, codigo: arregloCajas[i].Cc_caja, concepto: arregloCajas[i].Concepto, nombre: arregloCajas[i].Nombre_caja, tipo: 'caja'})
                            this.idInicialesEdicion.push({id:arregloCajas[i].Id_movimiento_caja, tipo: 'caja'})
                            this.montoFinal2 = this.montoFinal2 + Number(this.informacionContraMemoria2[this.informacionContraMemoria2.length - 1].monto)
                        }  
                    }
                }
                if(data.Contra === true){
                    this.bulContraMovimientoEditar2 = true
                    this.$store.commit('SET_BUL_MODAL', true)
                    this.conceptoContra3 = Concepto
                    this.codigoContableCaja5 = Cc_caja
                    this.codigoContableCaja6 = Nombre_caja
                    this.idInicialesEdicion.push({id: data.Id_movimiento_caja, tipo: 'caja'})
                    this.relacionIdPadre = data.Codigo_de_relacion
                }
            },

            //funcion para limitar la cantidad de texto de se muestra en un apartado

            limiteCaracteres(texto,cantidad){
                let textoListo
                if(texto.length > cantidad){
                    textoListo = texto.substring(0,(cantidad-3))
                    textoListo = `${textoListo}...`
                }else{
                    textoListo = texto
                }
                
                return textoListo
            },

            //funcion para agregar color al monto

            colorTextoContraMonto(monto){
                if(monto >= 0){
                    return 'texto-contra-monto1'
                }
                if(monto < 0){
                    return 'texto-contra-monto2'
                }
            },

            //funcion temporizadora de la ventana de color de los codigos contables

            relojColor(tipo){
                if(tipo === 'quitar'){
                    this.relojCodigo = setTimeout(() => {
                        this.bulCambioColor = false
                    }, 500); 
                }
                if(tipo === 'poner'){
                    clearTimeout(this.relojCodigo)
                    this.bulCambioColor = true
                }
            },

            //funcion para colocar el color seleccionado en el codigo contable 

            colorSeleccionadoCodigo(){
                if(this.tipoColor === 'encabezado'){
                    return 'background:#38c1ce;'
                }
                if(this.tipoColor === 'titulo'){
                    return 'background:rgb(219, 219, 219);'
                }
                if(this.tipoColor === 'codigo'){
                    return 'background:rgba(255, 255, 255, 1);'
                }
            },

            //funcion temporizadora de la ventana de color de los codigos contables

            relojColor2(tipo){
                if(tipo === 'quitar'){
                    this.relojCodigo = setTimeout(() => {
                        this.bulCambioColor2 = false
                    }, 500); 
                }
                if(tipo === 'poner'){
                    clearTimeout(this.relojCodigo)
                    this.bulCambioColor2 = true
                }
            },

            //funcion para colocar el color seleccionado en el codigo contable 

            colorSeleccionadoCodigo2(){
                if(this.tipoColor2 === 'encabezado'){
                    return 'background:#38c1ce;'
                }
                if(this.tipoColor2 === 'titulo'){
                    return 'background:rgb(219, 219, 219);'
                }
                if(this.tipoColor2 === 'codigo'){
                    return 'background:rgba(255, 255, 255, 1);'
                }
            },
        },
    }
</script>

<style scoped>
    .space-filter{
        width: 100%;
        height: 10px;
    }
    .container-all-export{
        width: 100%;
        position: relative;
        height: auto;
    }
    .container-form-export{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }
    .space-header{
        height: 80px;
        width: 100%;
    }
    .contenedor-filtros-seleccionados{
        width:calc(100% - 20px);
        margin-left:10px;
        margin-right:10px;
        background:rgba(196,196,196,0.35); 
        border-radius:10px; 
        height:140px; 
        overflow:hidden scroll; 
        padding-bottom:10px;
        margin-top:20px;
    }
    .w-contra-1{
        width:25%;
    }
    .w-contra-2{
        width:65%;
    }
    .w-contra-3{
        width:10%;
        height:26px;
    }
    .alturaContenedor2{
        height:calc(100% + 90px);
    }
    .alturaContenedor1{
        height:calc(100% + 290px);
    }
    .alturaContenedor3{
        height:calc(100% + 390px);
    }
    .alturaContenedor4{
        height:calc(100% + 390px);
    }
    .ventana-limitador{
        background:white;
        top:0px;
        width:150px;
        border-radius:10px;
        height:auto;
        margin-left:-75px;
        display:none;
        opacity:0;
        transition: all 1s;
        position: fixed;
        background:rgb(231, 236, 236);
    }
    .fondo-degradado-recover{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
    }
    .font-predeterminado{
        margin-top: 7.5px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
    }
    .checkbox-mantener{
        width: 25px;
        height: 25px;
        border-radius:4px;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
    }
    .caja-mantener{
        width: 17px;
        height: 17px;
        background: #297F87;
        border-radius:2px;
        opacity: 0;
    }
    .contenedor-movimientos{
        cursor: pointer;
        width: 1400px;
        height: 30px;
        border-bottom: #6464646b 2px solid;
        transition: all 0.4s;
    }
    .caja-editar-caja{
        position: absolute;
        width:50px;
        right:50px;
        height: 30px;
        background:rgba(217, 217, 218);
        padding-left:12px;
        backdrop-filter: blur(50px);
        border-bottom: #6464646b 2px solid;
        border-left: #6464646b 2px solid;
    }
    .caja-eliminar-caja{
        position: absolute;
        width:50px;
        height: 30px;
        background:rgba(217, 217, 218);
        padding-right:12px;
        backdrop-filter: blur(50px);
        border-bottom: #6464646b 2px solid;
    }
    .contenedor-caja{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .entrada,.salida{
        width: 100px;
    }
    .salida{
        margin-left:25px;
    }
    .cajaCheckMovimiento{
        width: 100%;
    }
    .texto-check{
        margin-top: 6px;
    }
    .contenedor-botones{
        margin-top:50px;
        width: 100%;
    }
    .button-exportar{
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        width: 200px;
        margin-left: 0%;
    }
    .contenedor-caja-historial,.contenedor-caja-historial2,.contenedor-caja-historial4,.contenedor-caja-historial5{
        position:relative;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 20px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 50px;
        margin-bottom: 10px;
    }
    .card{
        position:relative;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 60px;
        padding-top: 50px;
        padding-bottom: 50px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .contenedor-caja-historial2{
        height: 1000px;
        padding-top: 100px;
    }
    .contenedor-caja-historial4{
        padding-top: 0px;
    }
    .contenedor-caja-historial5{
        height: 800px;
        padding-top: 0px;
        margin-left:0%;
    }
    .contenedor-altura{
        height:250px;
    }
    .contenedor-altura2{
        height:0px;
    }
    .altura-responsive{
        height:600px;
    }
    .altura-responsive2{
        height:300px;
    }
    .altura-responsive3{
        height:600px;
    }
    .altura-responsive4{
        height:760px;
    }
    .caja-filtro{
        background:rgba(196,196,196,0.25);
        width: 100px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        left: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .contenedor-check1,.contenedor-check2{
        background:rgba(196,196,196,0.25);
        width: 120px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .contenedor-check2{
        width: 160px;
        right:160px;
    }
    .caja-filtros{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        margin-top:40px;
        border-radius:0px 0px 10px 10px;
        transition: all 1s;
        overflow: hidden;
    }
    .simboloIzquierda{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: 7.5px;
        transition: all 1s;
    }
    .simboloDerecha{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: -3.5px;
        transition: all 1s;
    }
    .margin-izquierda,.margin-izquierda2{
        margin-left:4%;
    }
    .input,.input2,.input3,.inputCodigoContable1,.inputCodigoContable2,.inputCaja1,.inputCaja2,.inputContra{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 30px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
        margin-top:0px ;
    }
    .input2{
        margin-bottom: 50px;
    }
    .input3{
        margin-bottom: 40px;
    }
    .inputCodigoContable1,.inputCaja1{
        margin-bottom: 40px;
        width: 30%;
        border-radius:10px 0px 0px 10px;
    }
    .inputCodigoContable2,.inputCaja2{
        margin-bottom: 40px;
        width: 70%;
        border-radius:0px 10px 10px 0px;
        border-left: #6464646b 0px solid;
        padding-right: 30px;
    }
    .inputContra{
        margin-bottom: 40px;
        width: 75%;
        margin-left:12.5%;
    }
    .ancho-responsive{
        width:380px;
    }
    .optionCambiado{
        border: #6464646b 2px solid;
    }
    .sumar-otro-codigo-contable,.sumar-otra-caja{
        position: absolute;
        top:-0.5px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/pictures/agregar-input.svg);
        transition: all 0.5s;
        margin-top:6px;
        right: 7.5px;
        cursor: pointer;
    }
    .cuadro-de-color{
        width: 20px;
        height: 20px;
        border:#6464646b 2px solid;
        border-radius: 4px;
        position: absolute;
        top: 4.5px;
        right: 35px;
    }
    .caja-codigos-contables,.caja-cajasExistentes{
        position: absolute;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 100%;
        height: 150px;
        top:56px;
        border-radius:10px;
        background: rgb(253,253,254);
        overflow: hidden scroll;
        padding: 10px;
    }
    .contenedor-caja-color{
        position:absolute;
        width:430px;
        height:160px;
    }
    .caja-color-codigos-contables,.caja-color-codigos-contables2{
        position: absolute;
        width: 150px;
        right: 0px;
        height: 90px;
        background: white;
        border-radius: 10px;
        top:-75px;
        padding: 10px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
    }
    .seleccionCodigoContable,.seleccionCaja,.seleccionCodigoContable2{
        cursor: pointer;
        width: 100%;
        height: 30px;
        border-bottom: #6464646b 2px solid;
        transition: all 0.4s;
    }
    .seleccionCodigoContable2{
        border-bottom: none;
        height: 100px;
    }
    .contra-movimiento-registro{
        margin-top:10px;
        width: 90%;
        height: 26px;
        transition: all 0.4s;
        background:rgba(255, 255, 255, 0.75);
    }
    .codigo{
        background:rgba(255, 255, 255, 1);
    }
    .titulo{
        background:rgb(219, 219, 219);
    }
    .encabezado{
        background:#38c1ce ;
    }
    .seleccionCodigoContable:hover.seleccionCaja:hover{
        transition: all 0.4s;
        background:rgba(202, 202, 202, 1);
    }
    .letrasSeleccionCodigoContable,.letrasSeleccionCaja{
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        overflow-x: hidden;
        position: relative;
        text-overflow: ellipsis;
    }
    .editarSeleccionCodigoContable,.editarSeleccionCaja{
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/pictures/lapiz.svg);
        transition: all 0.5s;
        cursor: pointer;
    }
    .borrarSeleccionCodigoContable,.borrarSeleccionCaja,.borrarSeleccionContraMovimiento{
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/pictures/papelera.svg);
        transition: all 0.5s;
        cursor: pointer;
    }
    .borrarSeleccionContraMovimiento{
        top:0px;
    }
    .label,.labelContra{
        font-family: Rubik;
        font-weight:bold ;
    }
    .labelContra{
        margin-left:12.5% ;
    }
    /*  */
    .caja-enunciado{
        position:relative;
        width: 1400px;
        background:rgb(196,214,216);
        border-radius:10px;
    }
    .caja-mostrarioMovimientos{
        position:relative;
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        border-radius:10px;
        transition: all 1s;
        margin-top:15px;
        overflow:scroll scroll;
        direction: ltr;
        padding:0px;
    }
    .caja-mostrarioMovimientos::-webkit-scrollbar{
        height: 14px;
    }
    .caja-mostrarioMovimientos::-webkit-scrollbar-corner{
        background: transparent;
        width:0px;
    }
    /*  */
    .checkbox,.checkbox2,.checkboxMovimiento,.checkboxM,.checkboxM2{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 10px;
        margin-top:-8px;
        background: white;
    }
    .caja,.caja2,.cajaMovimiento,.cajaM,.cajaM2{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
    }
    .cajaM,.cajaM2{
        width: 10px;
        height: 10px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:2.5px;
    }
    .cajaMovimiento,.checkboxMovimiento{
        border-radius: 0px;
    }
    .checkboxM,.checkboxM2{
        margin-right: 0px;
        border-radius:5px;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        margin-top:0px;
    }
    .exportar-informacion{
        width: 60%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
    }
    .cuadro-filtro-seleccionado{
        float:left;
        background: linear-gradient(153.24deg, rgba(0, 145, 142, 0.4) 9.04%, rgba(255, 255, 255, 0) 100%);
        width: 130px;
        height:35px;
        padding:5px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        border-radius:10px ;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2), inset 0 0 5px white;
        margin-top:10px;
        margin-bottom:0px;
        margin-left:5%;
    }
    .contenedor-modal{
        z-index: 50;
        top: 0px;
        left: 0px;
        position: absolute;
        width: 100%;
        height: calc(100% + 645px);
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }
    .x-nuevo{
        position: absolute;
        top:0px;
        width: 27.5px;
        height: 27.5px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/pictures/x-negro.svg);
        transition: all 0.5s;
        margin-top:135px;
        right: 12.5%;
        cursor: pointer;
    }
    .texto-contra,.texto-contra-monto1,.texto-contra-monto2{
        color: black;
        font-family: Rubik;
        font-size:40px ;
        margin-bottom: 40px;
    }
    .texto-contra-monto1{
        background: rgb(11, 114, 40,1);
        padding: 10px 20px;
        border-radius:5px ;
        color: white;
    }
    .texto-contra-monto2{
        background: rgb(128, 18, 22,1);
        padding: 10px 20px;
        border-radius:5px ;
        color: white;
    }
    .card-contraPartida{
        background: rgba(255, 255, 255, 0.75);
        height: 1000px;
        width: 90%;
        transition: all 1s;
        position: relative;
    }
    .card-eliminarSeguro{
        background: rgba(255, 255, 255, 0.75);
        width: 70%;
        transition: all 1s;
        position: relative;
    }
    .card-despues-contra-izquierda{
        position:absolute;
        top:700px;
        left:0px;
        height:200px;
        width:20%;
        background: rgba(255, 255, 255, 0.75);
        transition: all 1s;
    }
    .contenedor-contra-movimientos{
        position:absolute;
        top:700px;
        left:20%;
        height:200px;
        width:60%;
        transition: all 1s;
        overflow:hidden scroll;
    }
    .card-despues-contra-derecha{
        position:absolute;
        top:700px;
        right:0px;
        height:200px;
        width:20%;
        background: rgba(255, 255, 255, 0.75);
        transition: all 1s;
    }
    .contenedor-terminar-boton,.contenedor-terminar-boton2{
        position:absolute;
        top:900px;
        left:0px;
        height:200px;
        width:100%;
        background: rgba(255, 255, 255, 0.75);
        transition: all 1s;
        border-radius:0px 0px 16px 16px;
    }
    .contenedor-terminar-boton2{
        position:absolute;
        top:400px;
    }
    .contenedor-seguro-boton2{
        height: auto;
        margin-top: 100px;
        width:100%;
        transition: all 1s;
        border-radius:0px 0px 16px 16px;
    }
    .contenedor-seguro-boton{
        height:200px;
        width:100%;
        transition: all 1s;
        border-radius:0px 0px 16px 16px;
    }
    .caja-green{
        background:#38c1ce ;
        height:20px;
        width:20px;
        border:1px solid rgb(71, 71, 71);
        cursor:pointer;
    }
    .caja-gray{
        background:rgb(219, 219, 219);
        height:20px;
        width:20px;
        border:1px solid rgb(71, 71, 71);
        cursor:pointer;
    }
    .caja-white{
        background:rgba(255, 255, 255, 1);
        height:20px;
        width:20px;
        border:1px solid rgb(71, 71, 71);
        cursor:pointer;
    }
    .select-caja-color:hover{
        background: #29282c77;
    }
    .margin-boton-exportar{
        margin-bottom: 0px;
    }
    @media (max-width: 992px) { 
        .ancho-responsive{
            width:50%;
        }
        .contenedor-caja-historial5{
            height: 1000px;
        }
        .contenedor-altura{
            height: 350px;
        }
        .alturaContenedor1{
            height:calc(100% + 490px);
        }
        .altura-responsive{
            height:700px;
        }
        .altura-responsive2{
            height:450px;
        }
        .alturaContenedor3{
            height:calc(100% + 490px);
        }
        .alturaContenedor4{
            height:calc(100% + 660px);
        }
        .altura-responsive3{
            height:800px;
        }
        .altura-responsive4{
            height:860px;
        }
    }
    @media (max-width: 768px) { 
        .ancho-responsive{
            width:80%;
        }
        .contenedor-altura{
            height: 420px;
        }
        .margin-izquierda2{
            margin-left:2%;
        }
        .alturaContenedor1{
            height:calc(100% + 670px);
        }
        .altura-responsive{
            height:900px;
        }
        .altura-responsive4{
            height:1060px;
        }
        .card-eliminarSeguro{
            width:90%;
        }
        .exportar-informacion{
            width:90%;
        }
    }
    @media (max-width: 578px) {
        .caja-filtro{
            top:60px;
        }
        .caja-filtros{
            margin-top:80px;
        }
        .contenedor-check2{
            right:auto;
            left:20px;
        }
        .margin-boton-exportar{
            margin-bottom: 30px;
            margin-top: -25px;
        }
        .w-contra-1,.w-contra-2,.w-contra-3{
            width:100%;
        }
        .contra-movimiento-registro{
            height:78px;
        }
    }
</style>